import React, { createContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useLocalStorage } from '../components/hooks/useLocalStorage';

export const ConfiguratorContext = createContext();

export const ConfiguratorProvider = ({ children }) => {
  // console.log('CONFIGURATOR PROVIDER ACTIVE');

  const data = useStaticQuery(graphql`
    query packagesQuery {
      weddings: wpPage(template: { templateName: { eq: "WeddingPackages" } }) {
        weddingPackagesACF {
          weddingsConfigurator {
            engagementPhotoShoot {
              oneHour {
                price
              }
              twoHours {
                price
              }
            }
            photoBooth {
              allInclusivePrice
              allInclusivePriceWithOnePhotographer
              allInclusivePriceWithOnePhotographerAndOneVideographer
              basicPackagePrice
              basicPackagePriceWithOnePhotographer
              basicPackagePriceWithOnePhotographerAndOneVideographer
              photoBoothInfo {
                text
                slideImage1 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage2 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage3 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage4 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
              }
            }
            photography {
              onePhotographer {
                averageColorCorrectedImagesFor8
                colorCorrectedImagesAmount8AndAbove
                colorCorrectedImagesAmountBelow8
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              secondPhotographer {
                averageColorCorrectedImagesFor8
                colorCorrectedImagesAmount8AndAbove
                colorCorrectedImagesAmountBelow8
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              weddingAlbum {
                by10 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                by12 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                weddingAlbumInfo {
                  text
                  slideImage1 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage2 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage3 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                }
                professionalAlbumDesignInfo {
                  text
                }
              }
              photoRetouching {
                retouchedPhotos10Price
                retouchedPhotos20Price
                retouchedPhotos30Price
              }
              photoRetouchingInfo {
                text
                beforeAfter {
                  example1 {
                    afterImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                    beforeImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                  }
                  example2 {
                    afterImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                    beforeImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            videography {
              oneVideographer {
                priceFor8
                priceFor8WithOnePhotographer
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              secondVideographer {
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              videoEditingOptions {
                cinematographyPrice
                cinematographyPriceWithSecondVideographer
                traditionalPrice
                traditionalPriceWithSecondVideographer
                trailerOnlyPrice
                trailerOnlyPriceWithSecondVideographer
                extendedTrailerPrice
                extendedTrailerPriceWithSecondVideographer
              }
              videoEditingOptionsInfo {
                cinematography {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                trailerOnly {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                extendedTrailer {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                traditional {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
              }
            }
            socialMediaPackage {
              photo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              video {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              photoAndVideo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
            }
            expeditedPhotoProcessing {
              price
              expeditedPhotoProcessingInfo {
                text
              }
            }
            storage {
              info
              photo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              video {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              photoVideo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
            }
            premiumPhotoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
            premiumVideoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
          }
        }
      }
      elopements: wpPage(template: { templateName: { eq: "Elopement" } }) {
        elopementPackagesACF {
          elopementsConfigurator {
            photography {
              onePhotographer {
                priceFor1
                pricePerHour
              }
              weddingAlbum {
                by10 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                by12 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                weddingAlbumInfo {
                  text
                  slideImage1 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage2 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage3 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage4 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage5 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                }
                professionalAlbumDesignInfo {
                  text
                }
              }
            }
            videography {
              oneVideographer {
                priceFor1
                pricePerHour
              }
            }
            officiant {
              price
            }
            storage {
              info
              photo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              video {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              photoVideo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
            }
            premiumPhotoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
            premiumVideoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
          }
        }
      }
      storage: wpPage(template: { templateName: { eq: "StoragePackages" } }) {
        storagePackagesACF {
          photoDownloadLinkPrice
          videoDownloadLinkPrice
          photoVideoDownloadLinkPrice
        }
      }
    }
  `);

  // dev only
  // const [isDev] = useState(process.env.IS_DEV || false);
  const [isDev] = useState(false || false);

  // local storage
  const { setLocalStorageValue, getLocalStorageValue } = useLocalStorage();

  //#region Handle key events
  const [keyPressed, setKeyPressed] = useState(false);

  const handleKeyPressed = (key) => {
    if (key === 13 && !keyPressed) {
      setKeyPressed(true);
    }
  };

  const handleKeyUp = () => {
    setKeyPressed(false);
  };

  const handlePreventDefault = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
    }
  };
  //#endregion

  //#region Packages data
  // set default value for local storage packageConfiguratorWeddings,
  // packageConfiguratorElopements, and packageConfiguratorStorage,
  // and return the value from local storage
  const [packageConfiguratorWeddings, setPackageConfiguratorWeddings] =
    useState(() => {
      const storedValue = getLocalStorageValue('packageConfiguratorWeddings');
      return storedValue !== null ? storedValue : false;
    });

  const [packageConfiguratorElopements, setPackageConfiguratorElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('packageConfiguratorElopements');
      return storedValue !== null ? storedValue : false;
    });

  const [packageConfiguratorStorage, setPackageConfiguratorStorage] = useState(
    () => {
      const storedValue = getLocalStorageValue('packageConfiguratorStorage');
      return storedValue !== null ? storedValue : false;
    }
  );
  //#endregion

  //#region Main values
  // grand total
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [grandTotalPriceElopements, setGrandTotalPriceElopements] = useState(0);
  const [grandTotalPriceStorage, setGrandTotalPriceStorage] = useState(0);

  const configuratorWeddings =
    data.weddings && data.weddings.weddingPackagesACF
      ? data.weddings.weddingPackagesACF.weddingsConfigurator
      : null;
  // const configuratorElopements =
  //   data.elopements.elopementPackagesACF.elopementsConfigurator;
  // const configuratorStorage = data.storage.storagePackagesACF;
  const configuratorElopements =
    data.elopements && data.elopements.elopementPackagesACF
      ? data.elopements.elopementPackagesACF.elopementsConfigurator
      : null;
  const configuratorStorage =
    data.storage && data.storage.storagePackagesACF
      ? data.storage.storagePackagesACF
      : null;
  // values for modals in child component PackagesConfiguratorWeddings
  const weddingAlbumInfoModal =
    configuratorWeddings.photography.weddingAlbum.weddingAlbumInfo;
  const professionalAlbumDesignInfoModal =
    configuratorWeddings.photography.weddingAlbum.professionalAlbumDesignInfo;

  // values for modals in child component PackagesConfiguratorElopements
  const weddingAlbumInfoModalElopements =
    configuratorElopements.photography.weddingAlbum.weddingAlbumInfo;
  const professionalAlbumDesignInfoModalElopements =
    configuratorElopements.photography.weddingAlbum.professionalAlbumDesignInfo;

  // weddings data
  const onePhotographer = configuratorWeddings.photography.onePhotographer;
  const secondPhotographer =
    configuratorWeddings.photography.secondPhotographer;

  const weddingAlbum10 = configuratorWeddings.photography.weddingAlbum.by10;
  const weddingAlbum12 = configuratorWeddings.photography.weddingAlbum.by12;

  const retouchedPhotos10 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos10Price;
  const retouchedPhotos20 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos20Price;
  const retouchedPhotos30 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos30Price;

  const photoRetouchingInfo =
    configuratorWeddings.photography.photoRetouchingInfo;

  const oneVideographer = configuratorWeddings.videography.oneVideographer;
  const secondVideographer =
    configuratorWeddings.videography.secondVideographer;

  const videoEditingOptions =
    configuratorWeddings.videography.videoEditingOptions;
  const cinematographyInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.cinematography;
  const trailerOnlyInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.trailerOnly;
  const extendedTrailerInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.extendedTrailer;
  const traditionalInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.traditional;

  const engagementOneHour = configuratorWeddings.engagementPhotoShoot.oneHour;
  const engagementTwoHours = configuratorWeddings.engagementPhotoShoot.twoHours;

  const photoBooth = configuratorWeddings.photoBooth;
  const photoBoothInfo = configuratorWeddings.photoBooth.photoBoothInfo;

  const socialMediaPackage = configuratorWeddings.socialMediaPackage;

  const expeditedPhotoProcessing =
    configuratorWeddings.expeditedPhotoProcessing;
  const expeditedPhotoProcessingInfo =
    configuratorWeddings.expeditedPhotoProcessing.expeditedPhotoProcessingInfo;

  const storage = configuratorWeddings.storage;

  const premiumPhotoPackage = configuratorWeddings.premiumPhotoPackage;
  const premiumVideoPackage = configuratorWeddings.premiumVideoPackage;

  const premiumPhotoPackageElopements =
    configuratorElopements.premiumPhotoPackage;
  const premiumVideoPackageElopements =
    configuratorElopements.premiumVideoPackage;
  //#endregion

  // Photography state
  //#region One photographer
  const [onePhotographerSelected, setOnePhotographerSelected] = useState(() => {
    const storedValue = getLocalStorageValue('onePhotographerSelected');
    return storedValue !== null ? storedValue : false;
  });
  const [onePhotographerControl, setOnePhotographerControl] = useState('');
  const [onePhotographerHour, setOnePhotographerHour] = useState(() => {
    const storedValue = getLocalStorageValue('onePhotographerHour');
    return storedValue !== null ? storedValue : 8;
  });
  const [onePhotographerCCImages, setOnePhotographerCCImages] = useState(
    Number(onePhotographer.averageColorCorrectedImagesFor8)
  );
  const [onePhotographerPriceDefault] = useState(
    Number(onePhotographer.priceFor8)
  );
  const [onePhotographerPrice, setOnePhotographerPrice] = useState(
    Number(onePhotographer.priceFor8)
  );
  const [onePhotographerPriceForBelow8] = useState(
    Number(onePhotographer.pricePerHourBelow8)
  );
  const [onePhotographerPriceForAbove8] = useState(
    Number(onePhotographer.pricePerHour8AndAbove)
  );
  const [onePhotographerCCImages8] = useState(
    Number(onePhotographer.averageColorCorrectedImagesFor8)
  );
  const [onePhotographerCCImagesAbove8] = useState(
    Number(onePhotographer.colorCorrectedImagesAmount8AndAbove)
  );
  const [onePhotographerCCImagesBelow8] = useState(
    Number(onePhotographer.colorCorrectedImagesAmountBelow8)
  );

  // handle selected state
  const handleOnePhotographerSelected = (ev) => {
    ev.stopPropagation();
    clearStoragePackageState();
    // TODO: modified accessability
    // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
    setOnePhotographerSelected(!onePhotographerSelected);
    // TODO: modified accessability
    // handleKeyPressed(ev.keyCode);

    // clear Videographer control state
    setOneVideographerControl('');
    // }
  };

  // handle hours amount
  const handleOnePhotographerData = (control, ev) => {
    // handlePreventDefault(ev);
    // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
    // TODO: modified accessability
    // if (
    //   ev.type === 'click' ||
    //   ev.type === 'touchend' ||
    //   ((ev.key === 'Enter' || ev.code === 'Enter' || ev.keyCode === 13) && !keyPressed)
    // ) {
    // ev.preventDefault();
    ev.stopPropagation();

    if (onePhotographerSelected === false) {
      setOnePhotographerSelected(true);
    }

    if (control === 'decrease') {
      setOnePhotographerControl('decrease');
      if (onePhotographerHour > 0) {
        setOnePhotographerHour(onePhotographerHour - 1);
      }
    }

    if (control === 'increase') {
      setOnePhotographerControl('increase');
      setOnePhotographerHour(onePhotographerHour + 1);
    }
    // }
    // handleKeyPressed(ev.keyCode);
  };

  // handle cc images amount, and price, based on hours
  useEffect(() => {
    setOnePhotographerHour(onePhotographerHour);

    if (onePhotographerControl === 'decrease') {
      if (onePhotographerHour < 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages - onePhotographerCCImagesBelow8
        );

        setOnePhotographerPrice(
          onePhotographerPrice - onePhotographerPriceForBelow8
        );
      }

      if (onePhotographerHour > 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages - onePhotographerCCImagesAbove8
        );
        setOnePhotographerPrice(
          onePhotographerPrice - onePhotographerPriceForAbove8
        );
      }
    }

    if (onePhotographerControl === 'increase') {
      if (onePhotographerHour < 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages + onePhotographerCCImagesBelow8
        );
        setOnePhotographerPrice(
          onePhotographerPrice + onePhotographerPriceForBelow8
        );
      }
      if (onePhotographerHour > 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages + onePhotographerCCImagesAbove8
        );
        setOnePhotographerPrice(
          onePhotographerPrice + onePhotographerPriceForAbove8
        );
      }
    }

    if (onePhotographerHour === 8) {
      setOnePhotographerCCImages(onePhotographerCCImages8);
      setOnePhotographerPrice(onePhotographerPriceDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerHour, onePhotographerControl]);

  const handleOnePhotographerPrice = () => {
    if (
      onePhotographerHour === 8 &&
      onePhotographerPrice !== onePhotographerPriceDefault
    ) {
      setOnePhotographerPrice(onePhotographerPriceDefault);
      setOnePhotographerCCImages(onePhotographerCCImages8);
    }

    if (
      onePhotographerHour === 7 &&
      onePhotographerPrice !==
        onePhotographerPrice - onePhotographerPriceForBelow8
    ) {
      setOnePhotographerPrice(
        onePhotographerPriceDefault - onePhotographerPriceForBelow8
      );
      setOnePhotographerCCImages(
        onePhotographerCCImages - onePhotographerCCImagesBelow8
      );
    }

    if (
      onePhotographerHour === 6 &&
      onePhotographerPrice !==
        onePhotographerPrice - onePhotographerPriceForBelow8 * 2
    ) {
      setOnePhotographerPrice(
        onePhotographerPrice - onePhotographerPriceForBelow8 * 2
      );
      setOnePhotographerCCImages(
        onePhotographerCCImages - onePhotographerCCImagesBelow8 * 2
      );
    }

    if (onePhotographerHour > 8) {
      let correctedOnePhotographerPrice = onePhotographerPriceDefault;
      let correctedOnePhotographerCCImages = onePhotographerCCImages8;

      for (let i = 8; i < onePhotographerHour; i++) {
        correctedOnePhotographerPrice += onePhotographerPriceForAbove8;
        correctedOnePhotographerCCImages += onePhotographerCCImagesAbove8;
      }
      setOnePhotographerPrice(correctedOnePhotographerPrice);
      setOnePhotographerCCImages(correctedOnePhotographerCCImages);
    }
  };
  //#endregion

  //#region Premium package
  // photo
  const [premiumPhotoPackageSelected, setPremiumPhotoPackageSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue('premiumPhotoPackageSelected');
      return storedValue !== null ? storedValue : false;
    });
  const [premiumPhotoPackagePrice, setPremiumPhotoPackagePrice] = useState(
    Number(premiumPhotoPackage.price)
  );
  const [premiumPhotoPackagePriceDefault] = useState(
    Number(premiumPhotoPackage.price)
  );
  // TODO: if/when needed, combined with premiumPhotoPackagePriceDiscount
  // const [premiumPhotoPackagePriceDiscount] = useState(
  //   Number(premiumPhotoPackage.discount)
  // );
  const [premiumPhotoPackageDescription] = useState(
    premiumPhotoPackage.description
  );
  const [premiumPhotoPackagePopupTitle, setPremiumPhotoPackagePopupTitle] =
    useState(premiumPhotoPackage.popupTitle);
  // TODO: if/when needed
  // const [premiumPhotoPackagePopupTitleDefault] = useState(
  //   premiumPhotoPackage.popupTitle
  // );
  // const [premiumPhotoPackagePopupTitleDiscount] = useState(
  //   premiumPhotoPackage.popupTitleDiscount
  // );
  const [premiumPhotoPackagePopupBody] = useState(
    premiumPhotoPackage.popupBody
  );

  const handlePremiumPhotoPackageSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setPremiumPhotoPackageSelected(!premiumPhotoPackageSelected);
      handleKeyPressed(ev.keyCode);
    }
  };

  // video
  const [premiumVideoPackageSelected, setPremiumVideoPackageSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue('premiumVideoPackageSelected');
      return storedValue !== null ? storedValue : false;
    });

  const [premiumVideoPackagePrice, setPremiumVideoPackagePrice] = useState(
    Number(premiumVideoPackage.price)
  );
  const [premiumVideoPackagePriceDefault] = useState(
    Number(premiumVideoPackage.price)
  );
  const [premiumVideoPackagePriceDiscount] = useState(
    Number(premiumVideoPackage.discount)
  );
  const [premiumVideoPackageDescription, setPremiumVideoPackageDescription] =
    useState('');
  const [premiumVideoPackageDescriptionDefault] = useState(
    premiumVideoPackage.description
  );

  const [premiumVideoPackagePopupTitle, setPremiumVideoPackagePopupTitle] =
    useState(premiumVideoPackage.popupTitle);
  const [premiumVideoPackagePopupTitleDefault] = useState(
    premiumVideoPackage.popupTitle
  );
  const [premiumVideoPackagePopupTitleDiscount] = useState(
    premiumVideoPackage.popupTitleDiscount
  );
  const [premiumVideoPackagePopupBody] = useState(
    premiumVideoPackage.popupBody
  );

  const handlePremiumVideoPackageSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setPremiumVideoPackageSelected(!premiumVideoPackageSelected);
      handleKeyPressed(ev.keyCode);
    }
  };
  //#endregion

  //#region Second photographer
  const [secondPhotographerSelected, setSecondPhotographerSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('secondPhotographerSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [secondPhotographerControl, setSecondPhotographerControl] =
    useState('');
  const [secondPhotographerPriceDefault] = useState(
    Number(secondPhotographer.priceFor8)
  );
  const [secondPhotographerPrice, setSecondPhotographerPrice] = useState(
    Number(secondPhotographer.priceFor8)
  );
  const [secondPhotographerHour, setSecondPhotographerHour] = useState(() => {
    const storedValue = getLocalStorageValue('secondPhotographerHour');
    return storedValue !== null ? storedValue : 8;
  });
  const [secondPhotographerCCImages, setSecondPhotographerCCImages] = useState(
    Number(secondPhotographer.averageColorCorrectedImagesFor8)
  );
  const [secondPhotographerPriceForAbove8] = useState(
    Number(secondPhotographer.pricePerHour8AndAbove)
  );
  const [secondPhotographerPriceForBelow8] = useState(
    Number(secondPhotographer.pricePerHourBelow8)
  );
  const [secondPhotographerCCImages8] = useState(
    Number(secondPhotographer.averageColorCorrectedImagesFor8)
  );
  const [secondPhotographerCCImagesAbove8] = useState(
    Number(secondPhotographer.colorCorrectedImagesAmount8AndAbove)
  );
  const [secondPhotographerCCImagesBelow8] = useState(
    Number(secondPhotographer.colorCorrectedImagesAmountBelow8)
  );

  // handle selected state
  const handleSecondPhotographerSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setSecondPhotographerSelected(!secondPhotographerSelected);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle hours amount
  const handleSecondPhotographerData = (control, ev) => {
    handlePreventDefault(ev);

    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (secondPhotographerSelected === false) {
        setSecondPhotographerSelected(true);
      }

      if (control === 'decrease') {
        setSecondPhotographerControl('decrease');
        if (secondPhotographerHour > 4) {
          setSecondPhotographerHour(secondPhotographerHour - 1);
        }
      }

      if (control === 'increase') {
        setSecondPhotographerControl('increase');
        setSecondPhotographerHour(secondPhotographerHour + 1);
      }
    }

    handleKeyPressed(ev.keyCode);
  };

  // handle cc images amount and price based on hours
  useEffect(() => {
    setSecondPhotographerHour(secondPhotographerHour);

    if (secondPhotographerControl === 'decrease') {
      if (secondPhotographerHour < 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages - secondPhotographerCCImagesBelow8
        );

        setSecondPhotographerPrice(
          secondPhotographerPrice - secondPhotographerPriceForBelow8
        );
      }

      if (secondPhotographerHour > 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages - secondPhotographerCCImagesAbove8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice - secondPhotographerPriceForAbove8
        );
      }
    }

    if (secondPhotographerControl === 'increase') {
      if (secondPhotographerHour < 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages + secondPhotographerCCImagesBelow8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice + secondPhotographerPriceForBelow8
        );
      }
      if (secondPhotographerHour > 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages + secondPhotographerCCImagesAbove8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice + secondPhotographerPriceForAbove8
        );
      }
    }

    if (secondPhotographerHour === 8) {
      setSecondPhotographerCCImages(secondPhotographerCCImages8);
      setSecondPhotographerPrice(secondPhotographerPriceDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondPhotographerHour, secondPhotographerControl]);

  const handleSecondPhotographerPrice = () => {
    if (
      secondPhotographerHour === 8 &&
      secondPhotographerPrice !== secondPhotographerPriceDefault
    ) {
      setSecondPhotographerPrice(secondPhotographerPriceDefault);
      setSecondPhotographerCCImages(secondPhotographerCCImages8);
    }

    if (
      secondPhotographerHour === 7 &&
      secondPhotographerPrice !==
        secondPhotographerPrice - secondPhotographerPriceForBelow8
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8
      );
    }

    if (
      secondPhotographerHour === 6 &&
      secondPhotographerPrice !==
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 2
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 2
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 2
      );
    }

    if (
      secondPhotographerHour === 5 &&
      secondPhotographerPrice !==
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 3
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 3
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 3
      );
    }

    if (
      secondPhotographerHour === 4 &&
      secondPhotographerPrice !==
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 4
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPrice - secondPhotographerPriceForBelow8 * 4
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 4
      );
    }

    if (secondPhotographerHour > 8) {
      let correctedSecondPhotographerPrice = secondPhotographerPriceDefault;
      let correctedSecondPhotographerCCImages = secondPhotographerCCImages8;

      for (let i = 8; i < secondPhotographerHour; i++) {
        correctedSecondPhotographerPrice += secondPhotographerPriceForAbove8;
        correctedSecondPhotographerCCImages += secondPhotographerCCImagesAbove8;
      }
      setSecondPhotographerPrice(correctedSecondPhotographerPrice);
      setSecondPhotographerCCImages(correctedSecondPhotographerCCImages);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue('onePhotographerSelected', onePhotographerSelected);
    setLocalStorageValue('onePhotographerHour', onePhotographerHour);
    setLocalStorageValue(
      'secondPhotographerSelected',
      secondPhotographerSelected
    );
    setLocalStorageValue('secondPhotographerHour', secondPhotographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelected,
    onePhotographerHour,
    secondPhotographerSelected,
    secondPhotographerHour,
  ]);
  //#endregion

  // wedding albums
  //#region Wedding album 10
  const [weddingAlbum10Selected, setWeddingAlbum10Selected] = useState(() => {
    const storedValue = getLocalStorageValue('weddingAlbum10Selected');
    return storedValue !== null ? storedValue : false;
  });
  const [
    weddingAlbum10ProfessionalDesignSelected,
    setWeddingAlbum10ProfessionalDesignSelected,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10ProfessionalDesignSelected'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [weddingAlbum10Price, setWeddingAlbum10Price] = useState(0);
  const [weddingAlbum10PriceTotal, setWeddingAlbum10PriceTotal] = useState(0);
  const [weddingAlbum10PriceDefault] = useState(Number(weddingAlbum10.price));
  const [weddingAlbum10ShippingFee] = useState(
    Number(weddingAlbum10.shippingFee)
  );
  const [weddingAlbum10ShippingFeeTotal, setWeddingAlbum10ShippingFeeTotal] =
    useState(Number(weddingAlbum10.shippingFee));
  const [weddingAlbum10Control, setWeddingAlbum10Control] = useState('');
  const [weddingAlbum10Amount, setWeddingAlbum10Amount] = useState(() => {
    const storedValue = getLocalStorageValue('weddingAlbum10Amount');
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum10AmountFree, setWeddingAlbum10AmountFree] = useState(
    () => {
      const storedValue = getLocalStorageValue('weddingAlbum10AmountFree');
      return storedValue !== null ? storedValue : 0;
    }
  );
  const [
    weddingAlbum10ProfessionalDesignAmount,
    setWeddingAlbum10ProfessionalDesignAmount,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10ProfessionalDesignAmount'
    );
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum10Info, setWeddingAlbum10Info] = useState('Album');
  const [weddingAlbum10AmountFreeInfo, setWeddingAlbum10AmountFreeInfo] =
    useState('Album');
  const [
    weddingAlbum10ProfessionalDesignInfo,
    setWeddingAlbum10ProfessionalDesignInfo,
  ] = useState('Design');
  const [weddingAlbum10ProfessionalDesignPrice] = useState(
    Number(weddingAlbum10.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum10ProfessionalDesignPriceTotal,
    setWeddingAlbum10ProfessionalDesignPriceTotal,
  ] = useState(Number(weddingAlbum10.professionalAlbumDesignPrice));
  //#endregion

  //#region Wedding album 12
  const [weddingAlbum12Selected, setWeddingAlbum12Selected] = useState(() => {
    const storedValue = getLocalStorageValue('weddingAlbum12Selected');
    return storedValue !== null ? storedValue : false;
  });
  const [
    weddingAlbum12ProfessionalDesignSelected,
    setWeddingAlbum12ProfessionalDesignSelected,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12ProfessionalDesignSelected'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [weddingAlbum12Price, setWeddingAlbum12Price] = useState(0);
  const [weddingAlbum12PriceTotal, setWeddingAlbum12PriceTotal] = useState(0);
  const [weddingAlbum12PriceDefault] = useState(Number(weddingAlbum12.price));
  const [weddingAlbum12ShippingFee] = useState(
    Number(weddingAlbum12.shippingFee)
  );
  const [weddingAlbum12ShippingFeeTotal, setWeddingAlbum12ShippingFeeTotal] =
    useState(Number(weddingAlbum12.shippingFee));
  const [weddingAlbum12Control, setWeddingAlbum12Control] = useState('');
  const [weddingAlbum12Amount, setWeddingAlbum12Amount] = useState(() => {
    const storedValue = getLocalStorageValue('weddingAlbum12Amount');
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum12AmountFree, setWeddingAlbum12AmountFree] = useState(
    () => {
      const storedValue = getLocalStorageValue('weddingAlbum12AmountFree');
      return storedValue !== null ? storedValue : 0;
    }
  );
  const [
    weddingAlbum12ProfessionalDesignAmount,
    setWeddingAlbum12ProfessionalDesignAmount,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12ProfessionalDesignAmount'
    );
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum12Info, setWeddingAlbum12Info] = useState('Album');
  const [weddingAlbum12AmountFreeInfo, setWeddingAlbum12AmountFreeInfo] =
    useState('Album');
  const [
    weddingAlbum12ProfessionalDesignInfo,
    setWeddingAlbum12ProfessionalDesignInfo,
  ] = useState('Design');
  const [weddingAlbum12ProfessionalDesignPrice] = useState(
    Number(weddingAlbum12.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum12ProfessionalDesignPriceTotal,
    setWeddingAlbum12ProfessionalDesignPriceTotal,
  ] = useState(Number(weddingAlbum10.professionalAlbumDesignPrice));
  //#endregion

  const [uriChange, setUriChange] = useState(false);

  //#region Wedding albums methods
  // TODO: accessiblity state
  const handleWeddingAlbumSelected = (album) => {
    if (album === '10x10') {
      setWeddingAlbum10Selected(!weddingAlbum10Selected);
      setWeddingAlbum10ProfessionalDesignSelected(false);
      setUriChange(false);
    }
    if (album === '12x12') {
      setWeddingAlbum12Selected(!weddingAlbum12Selected);
      setWeddingAlbum12ProfessionalDesignSelected(false);
      setUriChange(false);
    }
  };

  // TODO: accessiblity state
  const handleWeddingAlbumProfessionalDesignSelected = (album) => {
    if (album === '10x10') {
      if (weddingAlbum10Selected) {
        setWeddingAlbum10ProfessionalDesignSelected(
          !weddingAlbum10ProfessionalDesignSelected
        );
      }
    }
    if (album === '12x12') {
      if (weddingAlbum12Selected) {
        setWeddingAlbum12ProfessionalDesignSelected(
          !weddingAlbum12ProfessionalDesignSelected
        );
      }
    }
  };

  // TODO: clean up
  // handle professional design 'selected' state
  // deps on wedding album 'selected' state
  // useEffect(() => {
  //   // FIXME:
  //   if (!uriChange) {
  //     if (weddingAlbum10Selected === false) {
  //       setWeddingAlbum10ProfessionalDesignSelected(false);
  //     }
  //     if (weddingAlbum12Selected === false) {
  //       setWeddingAlbum12ProfessionalDesignSelected(false);
  //     }
  //   }
  // }, [weddingAlbum10Selected, weddingAlbum12Selected, uriChange]);

  // TODO: accessiblity state
  const handleWeddingAlbumAmount = (album, control) => {
    // set uri change to false if page refresh has happened
    setUriChange(false);
    if (album === '10x10') {
      setWeddingAlbum10AmountFree(0);

      if (weddingAlbum10Selected === false) {
        setWeddingAlbum10Selected(true);
      }
      if (control === 'increase') {
        setWeddingAlbum10Control(control);
        setWeddingAlbum10Amount(weddingAlbum10Amount + 1);
        setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount + 1);
        setWeddingAlbum10AmountFree(0);
      }

      if (control === 'decrease') {
        setWeddingAlbum10Control(control);
        if (weddingAlbum10Amount > 1) {
          setWeddingAlbum10Amount(weddingAlbum10Amount - 1);
        }
      }
    }

    if (album === '12x12') {
      setWeddingAlbum12AmountFree(0);

      if (weddingAlbum12Selected === false) {
        setWeddingAlbum12Selected(true);
      }
      if (control === 'increase') {
        setWeddingAlbum12Control(control);
        setWeddingAlbum12Amount(weddingAlbum12Amount + 1);
        setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount + 1);
        setWeddingAlbum12AmountFree(0);
      }

      if (control === 'decrease') {
        setWeddingAlbum12Control(control);
        if (weddingAlbum12Amount > 1) {
          setWeddingAlbum12Amount(weddingAlbum12Amount - 1);
        }
      }
    }
  };

  // handle plural for all wedding albums
  useEffect(() => {
    weddingAlbum10Amount > 1
      ? setWeddingAlbum10Info('Albums')
      : setWeddingAlbum10Info('Album');

    weddingAlbum12Amount > 1
      ? setWeddingAlbum12Info('Albums')
      : setWeddingAlbum12Info('Album');

    weddingAlbum10AmountFree > 1
      ? setWeddingAlbum10AmountFreeInfo('Albums')
      : setWeddingAlbum10AmountFreeInfo('Album');

    weddingAlbum12AmountFree > 1
      ? setWeddingAlbum12AmountFreeInfo('Albums')
      : setWeddingAlbum12AmountFreeInfo('Album');

    weddingAlbum10ProfessionalDesignAmount > 1
      ? setWeddingAlbum10ProfessionalDesignInfo('Designs')
      : setWeddingAlbum10ProfessionalDesignInfo('Design');

    weddingAlbum12ProfessionalDesignAmount > 1
      ? setWeddingAlbum12ProfessionalDesignInfo('Designs')
      : setWeddingAlbum12ProfessionalDesignInfo('Design');
  }, [
    weddingAlbum10Amount,
    weddingAlbum10AmountFree,
    weddingAlbum12Amount,
    weddingAlbum12AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);

  // handle pricing for 10x10 wedding album
  useEffect(() => {
    if (weddingAlbum10Control === 'decrease') {
      setWeddingAlbum10Price(
        weddingAlbum10Price -
          weddingAlbum10PriceDefault -
          weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(
        weddingAlbum10ShippingFeeTotal - weddingAlbum10ShippingFee
      );
    }
    if (weddingAlbum10Control === 'increase') {
      setWeddingAlbum10Price(
        weddingAlbum10Price +
          weddingAlbum10PriceDefault +
          weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(
        weddingAlbum10ShippingFeeTotal + weddingAlbum10ShippingFee
      );
    }
    if (weddingAlbum10Amount === 1) {
      setWeddingAlbum10Price(
        weddingAlbum10PriceDefault + weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(weddingAlbum10ShippingFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum10Amount]);

  // re calculate price on page refresh (weddings and checkout page)
  const handlePriceTotalWeddingAlbum10 = (w10a) => {
    if (weddingAlbum10Amount === 1) {
      setWeddingAlbum10Price(
        Number(weddingAlbum10PriceDefault + weddingAlbum10ShippingFee)
      );
    }

    let correctedWeddingAlbum10Price = Number(
      weddingAlbum10PriceDefault + weddingAlbum10ShippingFee
    );

    let correctedShippingFee = Number(weddingAlbum10ShippingFee);

    let correctedWeddingAlbum10ProfessionalDesignPrice = 0;

    for (let i = 1; i < w10a; i++) {
      correctedWeddingAlbum10Price += weddingAlbum10PriceDefault;
      correctedWeddingAlbum10Price += weddingAlbum10ShippingFee;

      correctedShippingFee += weddingAlbum10ShippingFee;

      correctedWeddingAlbum10ProfessionalDesignPrice +=
        weddingAlbum10ProfessionalDesignPrice;
    }

    setWeddingAlbum10Price(Number(correctedWeddingAlbum10Price));
    setWeddingAlbum10ShippingFeeTotal(Number(correctedShippingFee));

    setWeddingAlbum10ProfessionalDesignPriceTotal(
      Number(correctedWeddingAlbum10ProfessionalDesignPrice)
    );
  };

  // handle price total for 10x10 wedding album
  // deps professional design
  useEffect(() => {
    setWeddingAlbum10PriceTotal(weddingAlbum10Price);
    if (weddingAlbum10ProfessionalDesignSelected) {
      setWeddingAlbum10PriceTotal(
        weddingAlbum10Price + weddingAlbum10ProfessionalDesignPriceTotal
      );
    }
  }, [
    weddingAlbum10Price,
    weddingAlbum10ProfessionalDesignSelected,
    weddingAlbum10ProfessionalDesignPriceTotal,
  ]);

  // handle pricing for 12x12 wedding album
  useEffect(() => {
    if (weddingAlbum12Control === 'decrease') {
      setWeddingAlbum12Price(
        weddingAlbum12Price -
          weddingAlbum12PriceDefault -
          weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(
        weddingAlbum12ShippingFeeTotal - weddingAlbum12ShippingFee
      );
    }
    if (weddingAlbum12Control === 'increase') {
      setWeddingAlbum12Price(
        weddingAlbum12Price +
          weddingAlbum12PriceDefault +
          weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(
        weddingAlbum12ShippingFeeTotal + weddingAlbum12ShippingFee
      );
    }
    if (weddingAlbum12Amount === 1) {
      setWeddingAlbum12Price(
        weddingAlbum12PriceDefault + weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(weddingAlbum12ShippingFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum12Amount]);

  // re calculate price on page refresh (weddings and checkout page)
  const handlePriceTotalWeddingAlbum12 = (w12a) => {
    if (weddingAlbum12Amount === 1) {
      setWeddingAlbum12Price(
        Number(weddingAlbum12PriceDefault + weddingAlbum12ShippingFee)
      );
    }

    let correctedWeddingAlbum12Price = Number(
      weddingAlbum12PriceDefault + weddingAlbum12ShippingFee
    );

    let correctedShippingFee = Number(weddingAlbum12ShippingFee);

    let correctedWeddingAlbum12ProfessionalDesignPrice = 0;

    for (let i = 1; i < w12a; i++) {
      correctedWeddingAlbum12Price += weddingAlbum12PriceDefault;
      correctedWeddingAlbum12Price += weddingAlbum12ShippingFee;

      correctedShippingFee += weddingAlbum12ShippingFee;

      correctedWeddingAlbum12ProfessionalDesignPrice +=
        weddingAlbum12ProfessionalDesignPrice;
    }

    setWeddingAlbum12Price(Number(correctedWeddingAlbum12Price));
    setWeddingAlbum12ShippingFeeTotal(Number(correctedShippingFee));

    setWeddingAlbum12ProfessionalDesignPriceTotal(
      Number(correctedWeddingAlbum12ProfessionalDesignPrice)
    );
  };

  // handle price total for 12x12 wedding album
  // deps professional design
  useEffect(() => {
    setWeddingAlbum12PriceTotal(weddingAlbum12Price);
    if (weddingAlbum12ProfessionalDesignSelected) {
      setWeddingAlbum12PriceTotal(
        weddingAlbum12Price + weddingAlbum12ProfessionalDesignPriceTotal
      );
    }
  }, [
    weddingAlbum12Price,
    weddingAlbum12ProfessionalDesignSelected,
    weddingAlbum12ProfessionalDesignPriceTotal,
  ]);

  // handle professional design
  // TODO: accessibility state
  const handleWeddingAlbumProfessionalDesignAmount = (album, control) => {
    // set uri change to false if page refresh has happened
    setUriChange(false);
    if (album === '10x10') {
      if (control === 'increase') {
        if (weddingAlbum10Amount > weddingAlbum10ProfessionalDesignAmount) {
          setWeddingAlbum10ProfessionalDesignAmount(
            weddingAlbum10ProfessionalDesignAmount + 1
          );
        }
        if (
          weddingAlbum10AmountFree > 0 &&
          weddingAlbum10AmountFree !== weddingAlbum10Amount
        ) {
          setWeddingAlbum10AmountFree(weddingAlbum10AmountFree - 1);
        }
      }

      if (control === 'decrease') {
        if (weddingAlbum10Amount > weddingAlbum10AmountFree) {
          setWeddingAlbum10AmountFree(weddingAlbum10AmountFree + 1);
        }
        setWeddingAlbum10ProfessionalDesignAmount(
          weddingAlbum10ProfessionalDesignAmount - 1
        );
      }
    }

    if (album === '12x12') {
      if (control === 'increase') {
        if (weddingAlbum12Amount > weddingAlbum12ProfessionalDesignAmount) {
          setWeddingAlbum12ProfessionalDesignAmount(
            weddingAlbum12ProfessionalDesignAmount + 1
          );
        }
        if (
          weddingAlbum12AmountFree > 0 &&
          weddingAlbum12AmountFree !== weddingAlbum12Amount
        ) {
          setWeddingAlbum12AmountFree(weddingAlbum12AmountFree - 1);
        }
      }

      if (control === 'decrease') {
        if (weddingAlbum12Amount > weddingAlbum12AmountFree) {
          setWeddingAlbum12AmountFree(weddingAlbum12AmountFree + 1);
        }

        setWeddingAlbum12ProfessionalDesignAmount(
          weddingAlbum12ProfessionalDesignAmount - 1
        );
      }
    }
  };

  // handle 'professional design' amount and price
  // deps on 'professional design' selected state, album amount
  useEffect(() => {
    if (!uriChange) {
      if (weddingAlbum10ProfessionalDesignSelected) {
        setWeddingAlbum10ProfessionalDesignPriceTotal(
          weddingAlbum10ProfessionalDesignPrice *
            weddingAlbum10ProfessionalDesignAmount
        );
        // setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
      }
      if (!weddingAlbum10ProfessionalDesignSelected) {
        setWeddingAlbum10ProfessionalDesignPriceTotal(
          weddingAlbum10ProfessionalDesignPrice *
            weddingAlbum10ProfessionalDesignAmount
        );
        setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
        setWeddingAlbum10AmountFree(0);
      }

      if (weddingAlbum12ProfessionalDesignSelected) {
        setWeddingAlbum12ProfessionalDesignPriceTotal(
          weddingAlbum12ProfessionalDesignPrice * weddingAlbum12Amount
        );
        // setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
      }
      if (!weddingAlbum12ProfessionalDesignSelected) {
        setWeddingAlbum12ProfessionalDesignPriceTotal(
          weddingAlbum12ProfessionalDesignPrice * weddingAlbum12Amount
        );
        setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
        setWeddingAlbum12AmountFree(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Amount,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12Amount,
    weddingAlbum12ProfessionalDesignAmount,
    weddingAlbum10ProfessionalDesignSelected,
    weddingAlbum12ProfessionalDesignSelected,
  ]);

  // handle professional design amount and free DIY amount
  useEffect(() => {
    // if (!uriChange) {
    if (weddingAlbum10ProfessionalDesignAmount > weddingAlbum10Amount) {
      setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
    }
    if (weddingAlbum12ProfessionalDesignAmount > weddingAlbum12Amount) {
      setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
    }
    if (weddingAlbum10ProfessionalDesignAmount > 0) {
      setWeddingAlbum10AmountFree(
        weddingAlbum10Amount - weddingAlbum10ProfessionalDesignAmount
      );
    }
    if (weddingAlbum12ProfessionalDesignAmount > 0) {
      setWeddingAlbum12AmountFree(
        weddingAlbum12Amount - weddingAlbum12ProfessionalDesignAmount
      );
    }
    if (weddingAlbum10ProfessionalDesignAmount === 0) {
      setWeddingAlbum10ProfessionalDesignSelected(false);
      setWeddingAlbum10AmountFree(0);
    }
    if (weddingAlbum12ProfessionalDesignAmount === 0) {
      setWeddingAlbum12ProfessionalDesignSelected(false);
      setWeddingAlbum12AmountFree(0);
    }
    // }
  }, [
    weddingAlbum10Amount,
    weddingAlbum12Amount,
    weddingAlbum10AmountFree,
    weddingAlbum12AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);

  // handle wedding album price total and
  // 'professional design' amount
  // deps wedding album amount
  useEffect(() => {
    if (!uriChange) {
      setWeddingAlbum10ProfessionalDesignPriceTotal(
        weddingAlbum10ProfessionalDesignPrice *
          weddingAlbum10ProfessionalDesignAmount
      );

      if (weddingAlbum10ProfessionalDesignAmount === 0) {
        setWeddingAlbum10ProfessionalDesignSelected(false);
        setWeddingAlbum10AmountFree(0);
      }

      setWeddingAlbum12ProfessionalDesignPriceTotal(
        weddingAlbum12ProfessionalDesignPrice *
          weddingAlbum12ProfessionalDesignAmount
      );

      if (weddingAlbum12ProfessionalDesignAmount === 0) {
        setWeddingAlbum12ProfessionalDesignSelected(false);
        setWeddingAlbum12AmountFree(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10ProfessionalDesignPriceTotal,
    weddingAlbum12ProfessionalDesignPriceTotal,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);

  // handle local storage for 10x10 wedding album
  useEffect(() => {
    setLocalStorageValue('weddingAlbum10Selected', weddingAlbum10Selected);
    setLocalStorageValue(
      'weddingAlbum10ProfessionalDesignSelected',
      weddingAlbum10ProfessionalDesignSelected
    );
    setLocalStorageValue('weddingAlbum10Amount', weddingAlbum10Amount);
    setLocalStorageValue('weddingAlbum10AmountFree', weddingAlbum10AmountFree);
    setLocalStorageValue(
      'weddingAlbum10ProfessionalDesignAmount',
      weddingAlbum10ProfessionalDesignAmount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Selected,
    weddingAlbum10ProfessionalDesignSelected,
    weddingAlbum10Amount,
    weddingAlbum10AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
  ]);

  // handle local storage for 12x12 wedding album
  useEffect(() => {
    setLocalStorageValue('weddingAlbum12Selected', weddingAlbum12Selected);
    setLocalStorageValue(
      'weddingAlbum12ProfessionalDesignSelected',
      weddingAlbum12ProfessionalDesignSelected
    );
    setLocalStorageValue('weddingAlbum12Amount', weddingAlbum12Amount);
    setLocalStorageValue('weddingAlbum12AmountFree', weddingAlbum12AmountFree);
    setLocalStorageValue(
      'weddingAlbum12ProfessionalDesignAmount',
      weddingAlbum12ProfessionalDesignAmount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum12Selected,
    weddingAlbum12ProfessionalDesignSelected,
    weddingAlbum12Amount,
    weddingAlbum12AmountFree,
    weddingAlbum12ProfessionalDesignAmount,
  ]);
  //#endregion

  //#region Photo retouching state
  const [retouchedPhotos10Price] = useState(retouchedPhotos10);
  const [photoRetouching10Selected, setPhotoRetouching10Selected] = useState(
    () => {
      const storedValue = getLocalStorageValue('photoRetouching10Selected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [retouchedPhotos20Price] = useState(retouchedPhotos20);
  const [photoRetouching20Selected, setPhotoRetouching20Selected] = useState(
    () => {
      const storedValue = getLocalStorageValue('photoRetouching20Selected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [retouchedPhotos30Price] = useState(retouchedPhotos30);
  const [photoRetouching30Selected, setPhotoRetouching30Selected] = useState(
    () => {
      const storedValue = getLocalStorageValue('photoRetouching30Selected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [photoRetouchingSelected, setPhotoRetouchingSelected] = useState(false);
  const [photoRetouchingPackageInfo, setPhotoRetouchingPackageInfo] =
    useState('');
  const [photoRetouchingPackagePrice, setPhotoRetouchingPackagePrice] =
    useState(0);

  const handlePhotoRetouching = (option, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (option === 10) {
        setPhotoRetouching10Selected(!photoRetouching10Selected);
        setPhotoRetouching20Selected(false);
        setPhotoRetouching30Selected(false);
        setPhotoRetouchingPackageInfo('10 retouched photos');
        setPhotoRetouchingPackagePrice(retouchedPhotos10Price);
      }
      if (option === 20) {
        setPhotoRetouching10Selected(false);
        setPhotoRetouching20Selected(!photoRetouching20Selected);
        setPhotoRetouching30Selected(false);
        setPhotoRetouchingPackageInfo('20 retouched photos');
        setPhotoRetouchingPackagePrice(retouchedPhotos20Price);
      }
      if (option === 30) {
        setPhotoRetouching10Selected(false);
        setPhotoRetouching20Selected(false);
        setPhotoRetouching30Selected(!photoRetouching30Selected);
        setPhotoRetouchingPackageInfo('30 retouched photos');
        setPhotoRetouchingPackagePrice(retouchedPhotos30Price);
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  useEffect(() => {
    if (
      photoRetouching10Selected ||
      photoRetouching20Selected ||
      photoRetouching30Selected
    ) {
      setPhotoRetouchingSelected(true);
    } else {
      setPhotoRetouchingSelected(false);
    }
  }, [
    photoRetouching10Selected,
    photoRetouching20Selected,
    photoRetouching30Selected,
  ]);

  // TODO: accessibility state
  const handlePhotoRetouchingSelected = () => {
    setPhotoRetouchingSelected(!photoRetouchingSelected);
    setPhotoRetouching10Selected(false);
    setPhotoRetouching20Selected(false);
    setPhotoRetouching30Selected(false);
  };

  // handle photo retouching pricing
  const handlePhotoRetouchingPricing = () => {
    if (photoRetouching10Selected) {
      setPhotoRetouchingPackageInfo('10 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos10Price);
    }
    if (photoRetouching20Selected) {
      setPhotoRetouchingPackageInfo('20 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos20Price);
    }
    if (photoRetouching30Selected) {
      setPhotoRetouchingPackageInfo('30 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos30Price);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'photoRetouching10Selected',
      photoRetouching10Selected
    );
    setLocalStorageValue(
      'photoRetouching20Selected',
      photoRetouching20Selected
    );
    setLocalStorageValue(
      'photoRetouching30Selected',
      photoRetouching30Selected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoRetouching10Selected,
    photoRetouching20Selected,
    photoRetouching30Selected,
  ]);
  //#endregion

  //#region Engagement photoshoot state
  const [engagementOneHourPrice] = useState(Number(engagementOneHour.price));
  const [engagementOneHourSelected, setEngagementOneHourSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('engagementOneHourSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [engagementTwoHoursPrice] = useState(Number(engagementTwoHours.price));
  const [engagementTwoHoursSelected, setEngagementTwoHoursSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('engagementTwoHoursSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [engagementPhotoShootSelected, setEngagementPhotoShootSelected] =
    useState(false);
  const [engagementPhotoShootPackageInfo, setEngagementPhotoShootPackageInfo] =
    useState('');
  const [engagementPhotoShootPrice, setEngagementPhotoShootPrice] = useState(0);

  // TODO: accessibility state
  const handleEngagementPhotoshoot = (option, ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (option === 1) {
        setEngagementOneHourSelected(!engagementOneHourSelected);
        setEngagementTwoHoursSelected(false);
        setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
        setEngagementPhotoShootPrice(engagementOneHourPrice);
      }
      if (option === 2) {
        setEngagementOneHourSelected(false);
        setEngagementTwoHoursSelected(!engagementTwoHoursSelected);
        setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
        setEngagementPhotoShootPrice(engagementTwoHoursPrice);
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // TODO: accessibility state
  const handleEngagementPhotoshootSelected = () => {
    setEngagementPhotoShootSelected(!engagementPhotoShootSelected);
    setEngagementOneHourSelected(false);
    setEngagementTwoHoursSelected(false);
  };

  useEffect(() => {
    if (engagementOneHourSelected || engagementTwoHoursSelected) {
      setEngagementPhotoShootSelected(true);
    } else {
      setEngagementPhotoShootSelected(false);
    }
  }, [engagementOneHourSelected, engagementTwoHoursSelected]);

  useEffect(() => {
    if (engagementOneHourSelected) {
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
    }
    if (engagementTwoHoursSelected) {
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementPhotoShootSelected]);

  // handle engagements pricing
  const handleEngagementsPricing = () => {
    if (engagementOneHourSelected) {
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
      setEngagementPhotoShootPrice(engagementOneHourPrice);
    }
    if (engagementTwoHoursSelected) {
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
      setEngagementPhotoShootPrice(engagementTwoHoursPrice);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'engagementOneHourSelected',
      engagementOneHourSelected
    );
    setLocalStorageValue(
      'engagementTwoHoursSelected',
      engagementTwoHoursSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementOneHourSelected, engagementTwoHoursSelected]);
  //#endregion

  //#region Expedited photo processing state
  const [expeditedPhotoProcessingPrice] = useState(
    Number(expeditedPhotoProcessing.price)
  );
  const [
    expeditedPhotoProcessingSelected,
    setExpeditedPhotoProcessingSelected,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'expeditedPhotoProcessingSelected'
    );
    return storedValue !== null ? storedValue : false;
  });

  // TODO: accessibility state
  const handleExpeditedPhotoProcessing = (option, ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (option === 1) {
        setExpeditedPhotoProcessingSelected(!expeditedPhotoProcessingSelected);
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'expeditedPhotoProcessingSelected',
      expeditedPhotoProcessingSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expeditedPhotoProcessingSelected]);
  //#endregion

  // Videography state
  //#region One videographer
  const [oneVideographerSelected, setOneVideographerSelected] = useState(() => {
    const storedValue = getLocalStorageValue('oneVideographerSelected');
    return storedValue !== null ? storedValue : false;
  });
  const [oneVideographerControl, setOneVideographerControl] = useState('');
  const [oneVideographerPriceDefault] = useState(
    Number(oneVideographer.priceFor8)
  );
  const [oneVideographerPrice, setOneVideographerPrice] = useState(
    Number(oneVideographer.priceFor8)
  );
  const [oneVideographerHour, setOneVideographerHour] = useState(() => {
    const storedValue = getLocalStorageValue('oneVideographerHour');
    return storedValue !== null ? storedValue : 8;
  });
  const [oneVideographerPriceForAbove8] = useState(
    Number(oneVideographer.pricePerHour8AndAbove)
  );
  const [oneVideographerPriceForBelow8] = useState(
    Number(oneVideographer.pricePerHourBelow8)
  );
  const [oneVideographerPriceWithOnePhotographer] = useState(
    Number(oneVideographer.priceFor8WithOnePhotographer)
  );

  // handle selected state
  const handleOneVideographerSelected = (ev) => {
    clearStoragePackageState();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOneVideographerSelected(!oneVideographerSelected);
      setCinematographyPrice(cinematographyPriceWithOneVideographer);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle hours amount
  const handleOneVideographerData = (control, ev) => {
    handlePreventDefault(ev);
    // console.log('HOVD', control, ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (oneVideographerSelected === false) {
        setOneVideographerSelected(true);
      }

      if (control === 'decrease') {
        setOneVideographerControl('decrease');
        if (oneVideographerHour > 4) {
          setOneVideographerHour(oneVideographerHour - 1);
        }
      }
      if (control === 'increase') {
        setOneVideographerControl('increase');
        setOneVideographerHour(oneVideographerHour + 1);
      }
    }
    handleKeyPressed(ev.keyCode);
  };

  // handle price based on hours
  useEffect(() => {
    setOneVideographerHour(oneVideographerHour);

    if (oneVideographerControl === 'decrease') {
      if (oneVideographerHour < 8) {
        setOneVideographerPrice(
          oneVideographerPrice - oneVideographerPriceForBelow8
        );
      }

      if (oneVideographerHour > 8) {
        setOneVideographerPrice(
          oneVideographerPrice - oneVideographerPriceForAbove8
        );
      }
    }

    if (oneVideographerControl === 'increase') {
      if (oneVideographerHour < 8) {
        setOneVideographerPrice(
          oneVideographerPrice + oneVideographerPriceForBelow8
        );
      }
      if (oneVideographerHour > 8) {
        setOneVideographerPrice(
          oneVideographerPrice + oneVideographerPriceForAbove8
        );
      }
    }

    if (oneVideographerHour === 8 && !onePhotographerSelected) {
      setOneVideographerPrice(oneVideographerPriceDefault);
    }

    if (oneVideographerHour === 8 && onePhotographerSelected) {
      setOneVideographerPrice(oneVideographerPriceWithOnePhotographer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerHour, oneVideographerControl, onePhotographerSelected]);

  const handleOneVideographerPrice = () => {
    if (!onePhotographerSelected) {
      if (
        oneVideographerHour === 8 &&
        oneVideographerPrice !== oneVideographerPriceDefault
      ) {
        setOneVideographerPrice(oneVideographerPriceDefault);
      }

      if (
        oneVideographerHour === 7 &&
        oneVideographerPrice !==
          oneVideographerPrice - oneVideographerPriceForBelow8
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8
        );
      }

      if (
        oneVideographerHour === 6 &&
        oneVideographerPrice !==
          oneVideographerPrice - oneVideographerPriceForBelow8 * 2
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 2
        );
      }

      if (
        oneVideographerHour === 5 &&
        oneVideographerPrice !==
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 3
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 3
        );
      }

      if (
        oneVideographerHour === 4 &&
        oneVideographerPrice !==
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 4
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 4
        );
      }
    }

    if (onePhotographerSelected) {
      if (
        oneVideographerHour === 8 &&
        oneVideographerPrice !== oneVideographerPriceWithOnePhotographer
      ) {
        setOneVideographerPrice(oneVideographerPriceWithOnePhotographer);
      }

      if (
        oneVideographerHour === 7 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8
        );
      }

      if (
        oneVideographerHour === 6 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 2
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 2
        );
      }

      if (
        oneVideographerHour === 5 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 3
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 3
        );
      }

      if (
        oneVideographerHour === 4 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 4
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 4
        );
      }
    }

    if (oneVideographerHour > 8) {
      let correctedOneVideographerPrice;

      if (onePhotographerSelected) {
        correctedOneVideographerPrice = oneVideographerPriceWithOnePhotographer;
      } else {
        correctedOneVideographerPrice = oneVideographerPriceDefault;
      }

      for (let i = 8; i < oneVideographerHour; i++) {
        correctedOneVideographerPrice += oneVideographerPriceForAbove8;
      }
      setOneVideographerPrice(correctedOneVideographerPrice);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue('oneVideographerSelected', oneVideographerSelected);
    setLocalStorageValue('oneVideographerHour', oneVideographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerSelected, oneVideographerHour]);
  //#endregion

  //#region Second videographer
  const [secondVideographerSelected, setSecondVideographerSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('secondVideographerSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [secondVideographerControl, setSecondVideographerControl] =
    useState('');
  const [secondVideographerPriceDefault] = useState(
    Number(secondVideographer.priceFor8)
  );
  const [secondVideographerPrice, setSecondVideographerPrice] = useState(
    Number(secondVideographer.priceFor8)
  );
  const [secondVideographerHour, setSecondVideographerHour] = useState(() => {
    const storedValue = getLocalStorageValue('secondVideographerHour');
    return storedValue !== null ? storedValue : 8;
  });
  const [secondVideographerPriceForAbove8] = useState(
    Number(secondVideographer.pricePerHour8AndAbove)
  );
  const [secondVideographerPriceForBelow8] = useState(
    Number(secondVideographer.pricePerHourBelow8)
  );

  // handle selected state
  // TODO: accessibility state
  const handleSecondVideographerSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setSecondVideographerSelected(!secondVideographerSelected);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle hours amount
  const handleSecondVideographerData = (control, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (secondVideographerSelected === false) {
        setSecondVideographerSelected(true);
      }

      if (control === 'decrease') {
        setSecondVideographerControl('decrease');
        if (secondVideographerHour > 4) {
          setSecondVideographerHour(secondVideographerHour - 1);
        }
      }
      if (control === 'increase') {
        setSecondVideographerControl('increase');
        setSecondVideographerHour(secondVideographerHour + 1);
      }
    }

    handleKeyPressed(ev.keyCode);
  };

  // handle price based on hours
  useEffect(() => {
    setSecondVideographerHour(secondVideographerHour);

    if (secondVideographerControl === 'decrease') {
      if (secondVideographerHour < 8) {
        setSecondVideographerPrice(
          secondVideographerPrice - secondVideographerPriceForBelow8
        );
      }

      if (secondVideographerHour > 8) {
        setSecondVideographerPrice(
          secondVideographerPrice - secondVideographerPriceForAbove8
        );
      }
    }

    if (secondVideographerControl === 'increase') {
      if (secondVideographerHour < 8) {
        setSecondVideographerPrice(
          secondVideographerPrice + secondVideographerPriceForBelow8
        );
      }
      if (secondVideographerHour > 8) {
        setSecondVideographerPrice(
          secondVideographerPrice + secondVideographerPriceForAbove8
        );
      }
    }

    if (secondVideographerHour === 8) {
      setSecondVideographerPrice(secondVideographerPriceDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondVideographerHour, secondVideographerControl]);

  const handleSecondVideographerPrice = () => {
    if (
      secondVideographerHour === 8 &&
      secondVideographerPrice !== secondVideographerPriceDefault
    ) {
      setSecondVideographerPrice(secondVideographerPriceDefault);
    }

    if (
      secondVideographerHour === 7 &&
      secondVideographerPrice !==
        secondVideographerPrice - secondVideographerPriceForBelow8
    ) {
      setSecondVideographerPrice(
        secondVideographerPriceDefault - secondVideographerPriceForBelow8
      );
    }

    if (
      secondVideographerHour === 6 &&
      secondVideographerPrice !==
        secondVideographerPrice - secondVideographerPriceForBelow8 * 2
    ) {
      setSecondVideographerPrice(
        secondVideographerPrice - secondVideographerPriceForBelow8 * 2
      );
    }

    if (
      secondVideographerHour === 5 &&
      secondVideographerPrice !==
        secondVideographerPrice - secondVideographerPriceForBelow8 * 3
    ) {
      setSecondVideographerPrice(
        secondVideographerPrice - secondVideographerPriceForBelow8 * 3
      );
    }

    if (
      secondVideographerHour === 4 &&
      secondVideographerPrice !==
        secondVideographerPrice - secondVideographerPriceForBelow8 * 4
    ) {
      setSecondVideographerPrice(
        secondVideographerPrice - secondVideographerPriceForBelow8 * 4
      );
    }

    if (secondVideographerHour > 8) {
      let correctedSecondVideographerPrice = secondVideographerPriceDefault;

      for (let i = 8; i < secondVideographerHour; i++) {
        correctedSecondVideographerPrice += secondVideographerPriceForAbove8;
      }
      setSecondVideographerPrice(correctedSecondVideographerPrice);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'secondVideographerSelected',
      secondVideographerSelected
    );
    setLocalStorageValue('secondVideographerHour', secondVideographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondVideographerSelected, secondVideographerHour]);
  //#endregion

  // video editing options state
  //#region Cinematography
  const [cinematographySelected, setCinematographySelected] = useState(() => {
    const storedValue = getLocalStorageValue('cinematographySelected');
    return storedValue !== null ? storedValue : false;
  });
  const [cinematographyPrice, setCinematographyPrice] = useState(0);
  const [cinematographyPriceWithOneVideographer] = useState(
    Number(videoEditingOptions.cinematographyPrice)
  );
  const [cinematographyPriceWithSecondVideographer] = useState(
    videoEditingOptions.cinematographyPriceWithSecondVideographer
  );

  // TODO: accessiblity state
  const handleCinematographySelected = () => {
    setCinematographySelected(!cinematographySelected);
    setTrailerOnlySelected(false);
    setExtendedTrailerSelected(false);
    setTraditionalSelected(false);
  };
  //#endregion

  //#region Trailer only
  const [trailerOnlySelected, setTrailerOnlySelected] = useState(() => {
    const storedValue = getLocalStorageValue('trailerOnlySelected');
    return storedValue !== null ? storedValue : false;
  });
  const [trailerOnlyPrice, setTrailerOnlyPrice] = useState(0);
  const [trailerOnlyPriceWithOneVideographer] = useState(
    Number(videoEditingOptions.trailerOnlyPrice)
  );
  const [trailerOnlyPriceWithSecondVideographer] = useState(
    Number(videoEditingOptions.trailerOnlyPriceWithSecondVideographer)
  );

  // TODO: accessiblity state
  const handleTrailerOnlySelected = () => {
    setCinematographySelected(false);
    setTrailerOnlySelected(!trailerOnlySelected);
    setExtendedTrailerSelected(false);
    setTraditionalSelected(false);
    if (traditionalSelected) {
      setCinematographySelected(false);
      if (trailerOnlySelected) {
        setTrailerOnlySelected(false);
      } else {
        setTrailerOnlySelected(true);
      }
      setExtendedTrailerSelected(false);
      setTraditionalSelected(true);
    }
  };
  //#endregion

  //#region Extended trailer
  const [extendedTrailerSelected, setExtendedTrailerSelected] = useState(() => {
    const storedValue = getLocalStorageValue('extendedTrailerSelected');
    return storedValue !== null ? storedValue : false;
  });
  const [extendedTrailerPrice, setExtendedTrailerPrice] = useState(0);
  const [extendedTrailerPriceWithOneVideographer] = useState(
    Number(videoEditingOptions.extendedTrailerPrice)
  );
  const [extendedTrailerPriceWithSecondVideographer] = useState(
    Number(videoEditingOptions.extendedTrailerPriceWithSecondVideographer)
  );

  // TODO: accessiblity state
  const handleExtendedTrailerSelected = () => {
    setCinematographySelected(false);
    setTrailerOnlySelected(false);
    setExtendedTrailerSelected(!extendedTrailerSelected);
    setTraditionalSelected(false);
    if (traditionalSelected) {
      setCinematographySelected(false);
      setTrailerOnlySelected(false);
      if (extendedTrailerSelected) {
        setExtendedTrailerSelected(false);
      } else {
        setExtendedTrailerSelected(true);
      }
      setTraditionalSelected(true);
    }
  };
  //#endregion

  //#region Traditional
  const [traditionalSelected, setTraditionalSelected] = useState(() => {
    const storedValue = getLocalStorageValue('traditionalSelected');
    return storedValue !== null ? storedValue : false;
  });
  const [traditionalPrice, setTraditionalPrice] = useState(0);
  const [traditionalPriceWithOneVideographer] = useState(
    Number(videoEditingOptions.traditionalPrice)
  );
  const [traditionalPriceWithSecondVideographer] = useState(
    Number(videoEditingOptions.traditionalPriceWithSecondVideographer)
  );

  // TODO: accessiblity state
  const handleTraditionalSelected = () => {
    setTraditionalSelected(!traditionalSelected);
    setCinematographySelected(false);
  };
  //#endregion

  //#region Handle cinematography, trailerOnly, extendedTrailer, traditional - pricing
  useEffect(() => {
    if (secondVideographerSelected) {
      setCinematographyPrice(cinematographyPriceWithSecondVideographer);
      setTrailerOnlyPrice(trailerOnlyPriceWithSecondVideographer);
      setExtendedTrailerPrice(extendedTrailerPriceWithSecondVideographer);
      setTraditionalPrice(traditionalPriceWithSecondVideographer);
    } else {
      setCinematographyPrice(cinematographyPriceWithOneVideographer);
      setTrailerOnlyPrice(trailerOnlyPriceWithOneVideographer);
      setExtendedTrailerPrice(extendedTrailerPriceWithOneVideographer);
      setTraditionalPrice(traditionalPriceWithOneVideographer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerSelected, secondVideographerSelected]);

  // handle local storage
  useEffect(() => {
    setLocalStorageValue('cinematographySelected', cinematographySelected);
    setLocalStorageValue('trailerOnlySelected', trailerOnlySelected);
    setLocalStorageValue('extendedTrailerSelected', extendedTrailerSelected);
    setLocalStorageValue('traditionalSelected', traditionalSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cinematographySelected,
    trailerOnlySelected,
    extendedTrailerSelected,
    traditionalSelected,
  ]);
  //#endregion

  //#region Premium Packages
  // Setting title in PackageModal
  const [isPremiumPackageAdded, setIsPremiumPackageAdded] = useState(false);

  // set title to either title or discountTitle
  // and adjust pricing of video
  useEffect(() => {
    if (!premiumPhotoPackageSelected) {
      setIsPremiumPackageAdded(false);
      setPremiumVideoPackagePopupTitle(premiumVideoPackagePopupTitleDefault);
      setPremiumVideoPackagePrice(premiumVideoPackagePriceDefault);
    }
    if (premiumPhotoPackageSelected || extendedTrailerSelected) {
      setIsPremiumPackageAdded(true);
      setPremiumVideoPackagePopupTitle(premiumVideoPackagePopupTitleDiscount);
      setPremiumVideoPackagePrice(premiumVideoPackagePriceDiscount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPhotoPackageSelected, extendedTrailerSelected]);

  // handle local storage for premium packages
  useEffect(() => {
    setLocalStorageValue(
      'premiumPhotoPackageSelected',
      premiumPhotoPackageSelected
    );
    setLocalStorageValue(
      'premiumVideoPackageSelected',
      premiumVideoPackageSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPhotoPackageSelected, premiumVideoPackageSelected]);
  //#endregion

  //#region Social media (sneak peek)
  // photo package state
  const [socialMediaPackagePhotoPrice] = useState(
    Number(socialMediaPackage.photo.price)
  );
  const [socialMediaPackagePhotoSelected, setSocialMediaPackagePhotoSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'socialMediaPackagePhotoSelected'
      );
      return storedValue !== null ? storedValue : false;
    });
  const [socialMediaPackagePhotoReels] = useState(socialMediaPackage.photo);

  // TODO: accessibility state
  const handleSocialMediaPackagePhotoSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setSocialMediaPackagePhotoSelected(!socialMediaPackagePhotoSelected);
      setSocialMediaPackageVideoSelected(false);
      setSocialMediaPackagePhotoAndVideoSelected(false);
      handleKeyPressed(ev.keyCode);
      // console.log('HANDLE SOCIAL MEDIA PHOTO', socialMediaPackagePhotoSelected);
    }
  };

  // video package state
  const [socialMediaPackageVideoPrice] = useState(
    Number(socialMediaPackage.video.price)
  );

  const [socialMediaPackageVideoSelected, setSocialMediaPackageVideoSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'socialMediaPackageVideoSelected'
      );
      return storedValue !== null ? storedValue : false;
    });
  const [socialMediaPackageVideoReels] = useState(socialMediaPackage.video);

  // TODO: accessibility state
  const handleSocialMediaPackageVideoSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setSocialMediaPackageVideoSelected(!socialMediaPackageVideoSelected);
      setSocialMediaPackagePhotoSelected(false);
      setSocialMediaPackagePhotoAndVideoSelected(false);
      handleKeyPressed(ev.keyCode);
    }
  };

  // photo and video package state
  const [socialMediaPackagePhotoAndVideoPrice] = useState(
    Number(socialMediaPackage.photoAndVideo.price)
  );

  const [
    socialMediaPackagePhotoAndVideoSelected,
    setSocialMediaPackagePhotoAndVideoSelected,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'socialMediaPackagePhotoAndVideoSelected'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [socialMediaPackagePhotoAndVideoReels] = useState(
    socialMediaPackage.photoAndVideo
  );

  // TODO: accessibility state
  const handleSocialMediaPackagePhotoAndVideoSelected = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setSocialMediaPackagePhotoAndVideoSelected(
        !socialMediaPackagePhotoAndVideoSelected
      );
      setSocialMediaPackagePhotoSelected(false);
      setSocialMediaPackageVideoSelected(false);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'socialMediaPackagePhotoSelected',
      socialMediaPackagePhotoSelected
    );
    setLocalStorageValue(
      'socialMediaPackageVideoSelected',
      socialMediaPackageVideoSelected
    );
    setLocalStorageValue(
      'socialMediaPackagePhotoAndVideoSelected',
      socialMediaPackagePhotoAndVideoSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    socialMediaPackagePhotoSelected,
    socialMediaPackageVideoSelected,
    socialMediaPackagePhotoAndVideoSelected,
  ]);
  //#endregion

  //#region Photo booth state
  const [photoBoothBasicPackagePriceDefault] = useState(
    Number(photoBooth.basicPackagePrice)
  );
  const [photoBoothBasicPackagePrice, setPhotoBoothBasicPackagePrice] =
    useState(Number(photoBooth.basicPackagePrice));
  const [photoBoothBasicPackagePriceWithOnePhotographer] = useState(
    Number(photoBooth.basicPackagePriceWithOnePhotographer)
  );
  // TODO: disabled for now
  // const [
  //   photoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer,
  //   setPhotoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer,
  // ] = useState(
  //   Number(photoBooth.basicPackagePriceWithOnePhotographerAndOneVideographer)
  // );
  const [photoBoothAllInclusivePriceDefault] = useState(
    Number(photoBooth.allInclusivePrice)
  );
  const [photoBoothAllInclusivePrice, setPhotoBoothAllInclusivePrice] =
    useState(Number(photoBooth.allInclusivePrice));
  const [photoBoothAllInclusivePriceWithOnePhotographer] = useState(
    Number(photoBooth.allInclusivePriceWithOnePhotographer)
  );
  // TODO: disabled for now
  // const [
  //   photoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer,
  //   setPhotoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer,
  // ] = useState(
  //   Number(photoBooth.allInclusivePriceWithOnePhotographerAndOneVideographer)
  // );
  const [photoBoothBasicSelected, setPhotoBoothBasicSelected] = useState(() => {
    const storedValue = getLocalStorageValue('photoBoothBasicSelected');
    return storedValue !== null ? storedValue : false;
  });
  const [photoBoothAllInclusiveSelected, setPhotoBoothAllInclusiveSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'photoBoothAllInclusiveSelected'
      );
      return storedValue !== null ? storedValue : false;
    });
  const [photoBoothPackagePrice, setPhotoBoothPackagePrice] = useState(0);

  // TODO: accessiblity state
  const handlePhotoBoothSelected = (option) => {
    if (option === 'basic') {
      setPhotoBoothBasicSelected(!photoBoothBasicSelected);
      setPhotoBoothAllInclusiveSelected(false);
    }
    if (option === 'all-inclusive') {
      setPhotoBoothBasicSelected(false);
      setPhotoBoothAllInclusiveSelected(!photoBoothAllInclusiveSelected);
    }
  };

  // handle photobooth pricing in sidebar
  useEffect(() => {
    if (photoBoothBasicSelected) {
      setPhotoBoothPackagePrice(photoBoothBasicPackagePrice);
    }
    if (photoBoothAllInclusiveSelected) {
      setPhotoBoothPackagePrice(photoBoothAllInclusivePrice);
    }
    if (onePhotographerSelected && photoBoothBasicSelected) {
      setPhotoBoothPackagePrice(photoBoothBasicPackagePriceWithOnePhotographer);
    }
    if (onePhotographerSelected && photoBoothAllInclusiveSelected) {
      setPhotoBoothPackagePrice(photoBoothAllInclusivePriceWithOnePhotographer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);

  // handle photobooth discount
  // when one photographer selected
  // when one photographer and
  // one videographer selected (disabled for now)
  useEffect(() => {
    if (onePhotographerSelected) {
      setPhotoBoothBasicPackagePrice(
        photoBoothBasicPackagePriceWithOnePhotographer
      );
      setPhotoBoothAllInclusivePrice(
        photoBoothAllInclusivePriceWithOnePhotographer
      );
      if (photoBoothBasicSelected) {
        setPhotoBoothPackagePrice(
          photoBoothBasicPackagePriceWithOnePhotographer
        );
      }
      if (photoBoothAllInclusiveSelected) {
        setPhotoBoothPackagePrice(
          photoBoothAllInclusivePriceWithOnePhotographer
        );
      }
    }

    // one videographer selected (disabled for now)
    // if (onePhotographerSelected && oneVideographerSelected) {
    //   console.log('PB OP & OV');
    //   setPhotoBoothBasicPackagePrice(
    //     photoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer
    //   );
    //   setPhotoBoothAllInclusivePrice(
    //     photoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer
    //   );
    //   if (photoBoothBasicSelected) {
    //     setPhotoBoothPackagePrice(
    //       photoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer
    //     );
    //   }
    //   if (photoBoothAllInclusiveSelected) {
    //     setPhotoBoothPackagePrice(
    //       photoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer
    //     );
    //   }
    // }

    if (
      ((photoBoothBasicSelected || photoBoothAllInclusiveSelected) &&
        !onePhotographerSelected) ||
      (!photoBoothBasicSelected &&
        !photoBoothAllInclusiveSelected &&
        !onePhotographerSelected)
    ) {
      setPhotoBoothBasicPackagePrice(photoBoothBasicPackagePriceDefault);
      setPhotoBoothAllInclusivePrice(photoBoothAllInclusivePriceDefault);

      if (photoBoothBasicSelected) {
        setPhotoBoothPackagePrice(photoBoothBasicPackagePriceDefault);
      }
      if (photoBoothAllInclusiveSelected) {
        setPhotoBoothPackagePrice(photoBoothAllInclusivePriceDefault);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelected,
    oneVideographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);

  // handle local storage
  useEffect(() => {
    setLocalStorageValue('photoBoothBasicSelected', photoBoothBasicSelected);
    setLocalStorageValue(
      'photoBoothAllInclusiveSelected',
      photoBoothAllInclusiveSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBoothBasicSelected, photoBoothAllInclusiveSelected]);
  //#endregion

  //#region Storage
  // handle storage package
  const [storageInfo] = useState(storage.info);
  const [storagePackagePrice, setStoragePackagePrice] = useState(0);

  // photo storage package
  const [storagePhotoPackageInfo] = useState(storage.photo.info);
  const [storagePhotoPackageOption1PopupText] = useState(
    storage.photo.options.option1PopupText
  );
  const [storagePhotoPackageOption1SidebarText] = useState(
    storage.photo.options.option1SidebarText
  );
  const [storagePhotoPackageOption1Price] = useState(
    storage.photo.options.option1Price
  );
  const [storagePhotoPackageOption2PopupText] = useState(
    storage.photo.options.option2PopupText
  );
  const [storagePhotoPackageOption2SidebarText] = useState(
    storage.photo.options.option2SidebarText
  );
  const [storagePhotoPackageOption2Price] = useState(
    storage.photo.options.option2Price
  );
  const [storagePhotoPackageOption3PopupText] = useState(
    storage.photo.options.option3PopupText
  );
  const [storagePhotoPackageOption3SidebarText] = useState(
    storage.photo.options.option3SidebarText
  );
  const [storagePhotoPackageOption3Price] = useState(
    storage.photo.options.option3Price
  );

  // video storage package
  const [storageVideoPackageInfo] = useState(storage.video.info);
  const [storageVideoPackageOption1PopupText] = useState(
    storage.video.options.option1PopupText
  );
  const [storageVideoPackageOption1SidebarText] = useState(
    storage.video.options.option1SidebarText
  );
  const [storageVideoPackageOption1Price] = useState(
    storage.video.options.option1Price
  );
  const [storageVideoPackageOption2PopupText] = useState(
    storage.video.options.option2PopupText
  );
  const [storageVideoPackageOption2SidebarText] = useState(
    storage.video.options.option2SidebarText
  );
  const [storageVideoPackageOption2Price] = useState(
    storage.video.options.option2Price
  );
  const [storageVideoPackageOption3PopupText] = useState(
    storage.video.options.option3PopupText
  );
  const [storageVideoPackageOption3SidebarText] = useState(
    storage.video.options.option3SidebarText
  );
  const [storageVideoPackageOption3Price] = useState(
    storage.video.options.option3Price
  );

  // photo and video storage package
  const [storagePhotoAndVideoPackageInfo] = useState(storage.photoVideo.info);
  const [storagePhotoAndVideoPackageOption1PopupText] = useState(
    storage.photoVideo.options.option1PopupText
  );
  const [storagePhotoAndVideoPackageOption1SidebarText] = useState(
    storage.photoVideo.options.option1SidebarText
  );
  const [storagePhotoAndVideoPackageOption1Price] = useState(
    storage.photoVideo.options.option1Price
  );
  const [storagePhotoAndVideoPackageOption2PopupText] = useState(
    storage.photoVideo.options.option2PopupText
  );
  const [storagePhotoAndVideoPackageOption2SidebarText] = useState(
    storage.photoVideo.options.option2SidebarText
  );
  const [storagePhotoAndVideoPackageOption2Price] = useState(
    storage.photoVideo.options.option2Price
  );
  const [storagePhotoAndVideoPackageOption3PopupText] = useState(
    storage.photoVideo.options.option3PopupText
  );
  const [storagePhotoAndVideoPackageOption3SidebarText] = useState(
    storage.photoVideo.options.option3SidebarText
  );
  const [storagePhotoAndVideoPackageOption3Price] = useState(
    storage.photoVideo.options.option3Price
  );

  // handle storage state
  const [storagePackageSelected, setStoragePackageSelected] = useState(() => {
    const storedValue = getLocalStorageValue('storagePackageSelected');
    return storedValue !== null ? storedValue : false;
  });

  // TODO: accessibility state
  const handleStoragePackageSelected = () => {
    setStoragePackageSelected(!storagePackageSelected);
  };

  // clear storage package state
  useEffect(() => {
    if (storagePackageSelected === false) {
      clearStoragePackageState();
    }
  }, [
    storagePackageSelected,
    onePhotographerSelected,
    oneVideographerSelected,
  ]);

  // clear storage package state on package change
  // examp. if storage is selected with one photographer
  // then when one videographer is added clear storage package state
  const clearStoragePackageState = () => {
    setCheckedStorageValue(null);
    setCheckedStorageValueModal(null);
    setToggleCheckoutBtnInfo(false);
    setStoragePackageSelected(false);
    setStoragePackagePrice(0);
  };

  const [storagePackageType, setStoragePackageType] = useState(() => {
    const storedValue = getLocalStorageValue('storagePackageType');
    return storedValue !== null ? storedValue : '';
  });

  const handleStoragePackage = (type) => {
    setStoragePackageType(type);
  };
  //#endregion

  //#region Storage in package modal
  // state is separate for weddings and elopements, handleStoragePackage()
  const [checkedStorageValueModal, setCheckedStorageValueModal] =
    useState(null);
  const [checkedStorageValue, setCheckedStorageValue] = useState(() => {
    const storedValue = getLocalStorageValue('checkedStorageValue');
    return storedValue !== null ? storedValue : null;
  });
  const [toggleCheckoutBtnInfo, setToggleCheckoutBtnInfo] = useState(false);

  // handle storage package inputs
  const handleStoragePackageChange = (price, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (checkedStorageValue === ev.target.value) {
        setCheckedStorageValue(null);
        setCheckedStorageValueModal(null);
        setToggleCheckoutBtnInfo(false);
        setStoragePackageSelected(false);
        setStoragePackagePrice(0);
      } else {
        setCheckedStorageValue(ev.target.value);
        setCheckedStorageValueModal(ev.target.value);
        setToggleCheckoutBtnInfo(true);
        setStoragePackageSelected(true);
        setStoragePackagePrice(Number(price));
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // sync inputs in modal and in sidebar with state
  useEffect(() => {
    if (checkedStorageValueModal !== null) {
      setCheckedStorageValue(checkedStorageValueModal);
    }
  }, [checkedStorageValueModal, checkedStorageValue, storagePackageType]);

  const handleStoragePrice = () => {
    if (storagePackageSelected) {
      if (storagePackageType === 'photo') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storagePhotoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storagePhotoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storagePhotoPackageOption3Price);
        }
      }
      if (storagePackageType === 'video') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storageVideoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storageVideoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storageVideoPackageOption3Price);
        }
      }
      if (storagePackageType === 'photoVideo') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption3Price);
        }
      }
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue('storagePackageSelected', storagePackageSelected);
    setLocalStorageValue('storagePackageType', storagePackageType);
    setLocalStorageValue('checkedStorageValue', checkedStorageValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storagePackageSelected, storagePackageType, checkedStorageValue]);
  //#endregion

  //#region Quick and dirty,
  // filter wordpress description data
  // if extended trailer selected
  useEffect(() => {
    let premiumVideoPackageDescriptionFiltered;

    if (typeof window !== 'undefined') {
      let parser = new DOMParser();
      let doc = parser.parseFromString(
        premiumVideoPackageDescriptionDefault,
        'text/html'
      );
      let liElements = doc.querySelectorAll('li');

      // Remove the first li element
      if (liElements[0]) {
        liElements[0].remove();
      }

      // Add a strong tag inside the second li element
      if (liElements[1]) {
        let strongElement = document.createElement('strong');
        strongElement.textContent = liElements[1].textContent;
        liElements[1].textContent = ''; // Clear the existing text
        liElements[1].appendChild(strongElement);
      }

      // Extract the updated HTML
      premiumVideoPackageDescriptionFiltered = doc.body.innerHTML;
    }
    if (extendedTrailerSelected) {
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionFiltered);
    } else {
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumVideoPackageDescriptionDefault, extendedTrailerSelected]);

  //#region Elopements data
  const onePhotographerElopements =
    configuratorElopements.photography.onePhotographer;
  const weddingAlbum10Elopements =
    configuratorElopements.photography.weddingAlbum.by10;
  const weddingAlbum12Elopements =
    configuratorElopements.photography.weddingAlbum.by12;
  // const weddingAlbumInfoModalElopements =
  //   configuratorElopements.photography.weddingAlbum.weddingAlbumInfo;
  // const professionalAlbumDesignInfoElopements =
  //   configuratorElopements.photography.weddingAlbum.professionalAlbumDesignInfo;
  const oneVideographerElopements =
    configuratorElopements.videography.oneVideographer;
  const officiantElopements = configuratorElopements.officiant;
  const storageElopements = configuratorElopements.storage;

  // photography state
  //#region One photographer elopement
  const [
    onePhotographerSelectedElopements,
    setOnePhotographerSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'onePhotographerSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [
    onePhotographerControlElopements,
    setOnePhotographerControlElopements,
  ] = useState('');
  const [onePhotographerHourElopements, setOnePhotographerHourElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('onePhotographerHourElopements');
      return storedValue !== null ? storedValue : 1;
    });
  const [onePhotographerPriceDefaultElopements] = useState(
    Number(onePhotographerElopements.priceFor1)
  );
  const [onePhotographerPriceElopements, setOnePhotographerPriceElopements] =
    useState(Number(onePhotographerElopements.priceFor1));
  const [onePhotographerPricePerHourElopements] = useState(
    Number(onePhotographerElopements.pricePerHour)
  );

  // handle selected state
  // TODO: accessibility state
  const handleOnePhotographerSelectedElopements = (ev) => {
    clearStoragePackageStateElopements();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOnePhotographerSelectedElopements(!onePhotographerSelectedElopements);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle hours amount
  const handleOnePhotographerDataElopements = (control, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (onePhotographerSelectedElopements === false) {
        setOnePhotographerSelectedElopements(true);
      }

      if (control === 'decrease') {
        setOnePhotographerControlElopements('decrease');
        if (onePhotographerHourElopements > 0) {
          setOnePhotographerHourElopements(onePhotographerHourElopements - 1);
        }
      }

      if (control === 'increase') {
        if (onePhotographerHourElopements < 4) {
          setOnePhotographerControlElopements('increase');
          setOnePhotographerHourElopements(onePhotographerHourElopements + 1);
        }
      }
    }

    handleKeyPressed(ev.keyCode);
  };

  // handle price based on hours
  useEffect(() => {
    setOnePhotographerHourElopements(onePhotographerHourElopements);

    if (onePhotographerControlElopements === 'decrease') {
      setOnePhotographerPriceElopements(
        onePhotographerPriceElopements - onePhotographerPricePerHourElopements
      );
    }
    if (onePhotographerControlElopements === 'increase') {
      setOnePhotographerPriceElopements(
        onePhotographerPriceElopements + onePhotographerPricePerHourElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerHourElopements, onePhotographerControlElopements]);

  const handleOnePhotographerPriceElopements = () => {
    if (onePhotographerHourElopements > 1) {
      let correctedOnePhotographerPrice = onePhotographerPriceDefaultElopements;

      for (let i = 1; i < onePhotographerHourElopements; i++) {
        correctedOnePhotographerPrice += onePhotographerPricePerHourElopements;
      }
      setOnePhotographerPriceElopements(correctedOnePhotographerPrice);
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'onePhotographerSelectedElopements',
      onePhotographerSelectedElopements
    );
    setLocalStorageValue(
      'onePhotographerHourElopements',
      onePhotographerHourElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerSelectedElopements, onePhotographerHourElopements]);
  //#endregion

  // Wedding albums elopements
  //#region Wedding album 10 elopements
  const [
    weddingAlbum10SelectedElopements,
    setWeddingAlbum10SelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10SelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [
    weddingAlbum10ProfessionalDesignSelectedElopements,
    setWeddingAlbum10ProfessionalDesignSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10ProfessionalDesignSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [weddingAlbum10PriceElopements, setWeddingAlbum10PriceElopements] =
    useState(0);
  const [
    weddingAlbum10PriceTotalElopements,
    setWeddingAlbum10PriceTotalElopements,
  ] = useState(0);
  const [weddingAlbum10PriceDefaultElopements] = useState(
    Number(weddingAlbum10Elopements.price)
  );
  const [weddingAlbum10ShippingFeeElopements] = useState(
    Number(weddingAlbum10Elopements.shippingFee)
  );
  const [
    weddingAlbum10ShippingFeeTotalElopements,
    setWeddingAlbum10ShippingFeeTotalElopements,
  ] = useState(Number(weddingAlbum10Elopements.shippingFee));
  const [weddingAlbum10ControlElopements, setWeddingAlbum10ControlElopements] =
    useState('');
  const [weddingAlbum10AmountElopements, setWeddingAlbum10AmountElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'weddingAlbum10AmountElopements'
      );
      return storedValue !== null ? storedValue : 1;
    });
  const [
    weddingAlbum10AmountFreeElopements,
    setWeddingAlbum10AmountFreeElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10AmountFreeElopements'
    );
    return storedValue !== null ? storedValue : 0;
  });
  const [
    weddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum10ProfessionalDesignAmountElopements'
    );
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum10InfoElopements, setWeddingAlbum10InfoElopements] =
    useState('Album');
  const [
    weddingAlbum10AmountFreeInfoElopements,
    setWeddingAlbum10AmountFreeInfoElopements,
  ] = useState('Album');
  const [
    weddingAlbum10ProfessionalDesignInfoElopements,
    setWeddingAlbum10ProfessionalDesignInfoElopements,
  ] = useState('Design');
  const [weddingAlbum10ProfessionalDesignPriceElopements] = useState(
    Number(weddingAlbum10Elopements.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum10ProfessionalDesignPriceTotalElopements,
  ] = useState(Number(weddingAlbum10Elopements.professionalAlbumDesignPrice));
  //#endregion

  //#region Wedding album 12 elopements
  const [
    weddingAlbum12SelectedElopements,
    setWeddingAlbum12SelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12SelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [
    weddingAlbum12ProfessionalDesignSelectedElopements,
    setWeddingAlbum12ProfessionalDesignSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12ProfessionalDesignSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [weddingAlbum12PriceElopements, setWeddingAlbum12PriceElopements] =
    useState(0);
  const [
    weddingAlbum12PriceTotalElopements,
    setWeddingAlbum12PriceTotalElopements,
  ] = useState(0);
  const [weddingAlbum12PriceDefaultElopements] = useState(
    Number(weddingAlbum12Elopements.price)
  );
  const [weddingAlbum12ShippingFeeElopements] = useState(
    Number(weddingAlbum12Elopements.shippingFee)
  );
  const [
    weddingAlbum12ShippingFeeTotalElopements,
    setWeddingAlbum12ShippingFeeTotalElopements,
  ] = useState(Number(weddingAlbum12Elopements.shippingFee));
  const [weddingAlbum12ControlElopements, setWeddingAlbum12ControlElopements] =
    useState('');
  const [weddingAlbum12AmountElopements, setWeddingAlbum12AmountElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'weddingAlbum12AmountElopements'
      );
      return storedValue !== null ? storedValue : 1;
    });
  const [
    weddingAlbum12AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12AmountFreeElopements'
    );
    return storedValue !== null ? storedValue : 0;
  });
  const [
    weddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'weddingAlbum12ProfessionalDesignAmountElopements'
    );
    return storedValue !== null ? storedValue : 1;
  });
  const [weddingAlbum12InfoElopements, setWeddingAlbum12InfoElopements] =
    useState('Album');
  const [
    weddingAlbum12AmountFreeInfoElopements,
    setWeddingAlbum12AmountFreeInfoElopements,
  ] = useState('Album');
  const [
    weddingAlbum12ProfessionalDesignInfoElopements,
    setWeddingAlbum12ProfessionalDesignInfoElopements,
  ] = useState('Design');
  const [weddingAlbum12ProfessionalDesignPriceElopements] = useState(
    Number(weddingAlbum12Elopements.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum12ProfessionalDesignPriceTotalElopements,
  ] = useState(Number(weddingAlbum10Elopements.professionalAlbumDesignPrice));
  //#endregion

  const [uriChangeElopements, setUriChangeElopements] = useState(false);

  //#region Wedding album elopements methods
  // TODO: accessibility state
  const handleWeddingAlbumSelectedElopements = (album) => {
    if (album === '10x10') {
      setWeddingAlbum10SelectedElopements(!weddingAlbum10SelectedElopements);
    }
    if (album === '12x12') {
      setWeddingAlbum12SelectedElopements(!weddingAlbum12SelectedElopements);
    }
  };

  // TODO: accessibility state
  const handleWeddingAlbumProfessionalDesignSelectedElopements = (album) => {
    if (album === '10x10') {
      if (weddingAlbum10SelectedElopements) {
        setWeddingAlbum10ProfessionalDesignSelectedElopements(
          !weddingAlbum10ProfessionalDesignSelectedElopements
        );
      }
    }
    if (album === '12x12') {
      if (weddingAlbum12SelectedElopements) {
        setWeddingAlbum12ProfessionalDesignSelectedElopements(
          !weddingAlbum12ProfessionalDesignSelectedElopements
        );
      }
    }
  };

  // TODO: clean up
  // handle professional design 'selected' state
  // deps on wedding album 'selected' state
  // useEffect(() => {
  //   if (weddingAlbum10SelectedElopements === false) {
  //     setWeddingAlbum10ProfessionalDesignSelectedElopements(false);
  //   }
  //   if (weddingAlbum12SelectedElopements === false) {
  //     setWeddingAlbum12ProfessionalDesignSelectedElopements(false);
  //   }
  // }, [weddingAlbum10SelectedElopements, weddingAlbum12SelectedElopements]);

  // TODO: accessibility state
  const handleWeddingAlbumAmountElopements = (album, control) => {
    if (album === '10x10') {
      setWeddingAlbum10AmountFreeElopements(0);

      if (weddingAlbum10SelectedElopements === false) {
        setWeddingAlbum10SelectedElopements(true);
      }
      if (control === 'increase') {
        setWeddingAlbum10ControlElopements(control);
        setWeddingAlbum10AmountElopements(weddingAlbum10AmountElopements + 1);
        setWeddingAlbum10ProfessionalDesignAmountElopements(
          weddingAlbum10AmountElopements + 1
        );
        setWeddingAlbum10AmountFreeElopements(0);
      }

      if (control === 'decrease') {
        setWeddingAlbum10ControlElopements(control);
        if (weddingAlbum10AmountElopements > 1) {
          setWeddingAlbum10AmountElopements(weddingAlbum10AmountElopements - 1);
        }
      }
    }

    if (album === '12x12') {
      setWeddingAlbum12AmountFreeElopements(0);

      if (weddingAlbum12SelectedElopements === false) {
        setWeddingAlbum12SelectedElopements(true);
      }
      if (control === 'increase') {
        setWeddingAlbum12ControlElopements(control);
        setWeddingAlbum12AmountElopements(weddingAlbum12AmountElopements + 1);
        setWeddingAlbum12ProfessionalDesignAmountElopements(
          weddingAlbum12AmountElopements + 1
        );
        setWeddingAlbum12AmountFreeElopements(0);
      }

      if (control === 'decrease') {
        setWeddingAlbum12ControlElopements(control);
        if (weddingAlbum12AmountElopements > 1) {
          setWeddingAlbum12AmountElopements(weddingAlbum12AmountElopements - 1);
        }
      }
    }
  };

  // handle plural for all wedding albums
  useEffect(() => {
    weddingAlbum10AmountElopements > 1
      ? setWeddingAlbum10InfoElopements('Albums')
      : setWeddingAlbum10InfoElopements('Album');

    weddingAlbum12AmountElopements > 1
      ? setWeddingAlbum12InfoElopements('Albums')
      : setWeddingAlbum12InfoElopements('Album');

    weddingAlbum10AmountFreeElopements > 1
      ? setWeddingAlbum10AmountFreeInfoElopements('Albums')
      : setWeddingAlbum10AmountFreeInfoElopements('Album');

    weddingAlbum12AmountFreeElopements > 1
      ? setWeddingAlbum12AmountFreeInfoElopements('Albums')
      : setWeddingAlbum12AmountFreeInfoElopements('Album');

    weddingAlbum10ProfessionalDesignAmountElopements > 1
      ? setWeddingAlbum10ProfessionalDesignInfoElopements('Designs')
      : setWeddingAlbum10ProfessionalDesignInfoElopements('Design');

    weddingAlbum12ProfessionalDesignAmountElopements > 1
      ? setWeddingAlbum12ProfessionalDesignInfoElopements('Designs')
      : setWeddingAlbum12ProfessionalDesignInfoElopements('Design');
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // handle pricing for 10x10 wedding album
  useEffect(() => {
    if (weddingAlbum10ControlElopements === 'decrease') {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceElopements -
          weddingAlbum10PriceDefaultElopements -
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeTotalElopements -
          weddingAlbum10ShippingFeeElopements
      );
    }
    if (weddingAlbum10ControlElopements === 'increase') {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceElopements +
          weddingAlbum10PriceDefaultElopements +
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeTotalElopements +
          weddingAlbum10ShippingFeeElopements
      );
    }
    if (weddingAlbum10AmountElopements === 1) {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceDefaultElopements +
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum10AmountElopements]);

  // re calculate price on page refresh (elopements and checkout page)
  const handlePriceTotalWeddingAlbum10Elopements = (w10a) => {
    if (weddingAlbum10AmountElopements === 1) {
      setWeddingAlbum10PriceElopements(
        Number(
          weddingAlbum10PriceDefaultElopements +
            weddingAlbum10ShippingFeeElopements
        )
      );
    }

    let correctedWeddingAlbum10Price = Number(
      weddingAlbum10PriceDefaultElopements + weddingAlbum10ShippingFeeElopements
    );

    let correctedShippingFee = Number(weddingAlbum10ShippingFeeElopements);

    let correctedWeddingAlbum10ProfessionalDesignPrice = 0;

    for (let i = 1; i < w10a; i++) {
      correctedWeddingAlbum10Price += weddingAlbum10PriceDefaultElopements;
      correctedWeddingAlbum10Price += weddingAlbum10ShippingFeeElopements;

      correctedShippingFee += weddingAlbum10ShippingFeeElopements;

      correctedWeddingAlbum10ProfessionalDesignPrice +=
        weddingAlbum10ProfessionalDesignPriceElopements;
    }

    setWeddingAlbum10PriceElopements(Number(correctedWeddingAlbum10Price));
    setWeddingAlbum10ShippingFeeTotalElopements(Number(correctedShippingFee));

    setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
      Number(correctedWeddingAlbum10ProfessionalDesignPrice)
    );
  };

  // handle price total for 10x10 wedding album
  // deps professional design
  useEffect(() => {
    setWeddingAlbum10PriceTotalElopements(weddingAlbum10PriceElopements);
    if (weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10PriceTotalElopements(
        weddingAlbum10PriceElopements +
          weddingAlbum10ProfessionalDesignPriceTotalElopements
      );
    }
  }, [
    weddingAlbum10PriceElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
  ]);

  // handle pricing for 12x12 wedding album
  useEffect(() => {
    if (weddingAlbum12ControlElopements === 'decrease') {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceElopements -
          weddingAlbum12PriceDefaultElopements -
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeTotalElopements -
          weddingAlbum12ShippingFeeElopements
      );
    }
    if (weddingAlbum12ControlElopements === 'increase') {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceElopements +
          weddingAlbum12PriceDefaultElopements +
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeTotalElopements +
          weddingAlbum12ShippingFeeElopements
      );
    }
    if (weddingAlbum12AmountElopements === 1) {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceDefaultElopements +
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum12AmountElopements]);

  // re calculate price on page refresh (elopements and checkout page)
  const handlePriceTotalWeddingAlbum12Elopements = (w12a) => {
    if (weddingAlbum12AmountElopements === 1) {
      setWeddingAlbum12PriceElopements(
        Number(
          weddingAlbum12PriceDefaultElopements +
            weddingAlbum12ShippingFeeElopements
        )
      );
    }

    let correctedWeddingAlbum12Price = Number(
      weddingAlbum12PriceDefaultElopements + weddingAlbum12ShippingFeeElopements
    );

    let correctedShippingFee = Number(weddingAlbum12ShippingFeeElopements);

    let correctedWeddingAlbum12ProfessionalDesignPrice = 0;

    for (let i = 1; i < w12a; i++) {
      correctedWeddingAlbum12Price += weddingAlbum12PriceDefaultElopements;
      correctedWeddingAlbum12Price += weddingAlbum12ShippingFeeElopements;

      correctedShippingFee += weddingAlbum12ShippingFeeElopements;

      correctedWeddingAlbum12ProfessionalDesignPrice +=
        weddingAlbum12ProfessionalDesignPriceElopements;
    }

    setWeddingAlbum12PriceElopements(Number(correctedWeddingAlbum12Price));
    setWeddingAlbum12ShippingFeeTotalElopements(Number(correctedShippingFee));

    setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
      Number(correctedWeddingAlbum12ProfessionalDesignPrice)
    );
  };

  // handle price total for 12x12 wedding album
  // deps professional design
  useEffect(() => {
    setWeddingAlbum12PriceTotalElopements(weddingAlbum12PriceElopements);
    if (weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12PriceTotalElopements(
        weddingAlbum12PriceElopements +
          weddingAlbum12ProfessionalDesignPriceTotalElopements
      );
    }
  }, [
    weddingAlbum12PriceElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
  ]);

  // handle professional design
  // TODO: accsessibility state
  const handleWeddingAlbumProfessionalDesignAmountElopements = (
    album,
    control
  ) => {
    if (album === '10x10') {
      if (control === 'increase') {
        if (
          weddingAlbum10AmountElopements >
          weddingAlbum10ProfessionalDesignAmountElopements
        ) {
          setWeddingAlbum10ProfessionalDesignAmountElopements(
            weddingAlbum10ProfessionalDesignAmountElopements + 1
          );
        }
        if (
          weddingAlbum10AmountFreeElopements > 0 &&
          weddingAlbum10AmountFreeElopements !== weddingAlbum10AmountElopements
        ) {
          setWeddingAlbum10AmountFreeElopements(
            weddingAlbum10AmountFreeElopements - 1
          );
        }
      }

      if (control === 'decrease') {
        if (
          weddingAlbum10AmountElopements > weddingAlbum10AmountFreeElopements
        ) {
          setWeddingAlbum10AmountFreeElopements(
            weddingAlbum10AmountFreeElopements + 1
          );
        }
        setWeddingAlbum10ProfessionalDesignAmountElopements(
          weddingAlbum10ProfessionalDesignAmountElopements - 1
        );
      }
    }

    if (album === '12x12') {
      if (control === 'increase') {
        if (
          weddingAlbum12AmountElopements >
          weddingAlbum12ProfessionalDesignAmountElopements
        ) {
          setWeddingAlbum12ProfessionalDesignAmountElopements(
            weddingAlbum12ProfessionalDesignAmountElopements + 1
          );
        }
        if (
          weddingAlbum12AmountFreeElopements > 0 &&
          weddingAlbum12AmountFreeElopements !== weddingAlbum12AmountElopements
        ) {
          setWeddingAlbum12AmountFreeElopements(
            weddingAlbum12AmountFreeElopements - 1
          );
        }
      }

      if (control === 'decrease') {
        if (
          weddingAlbum12AmountElopements > weddingAlbum12AmountFreeElopements
        ) {
          setWeddingAlbum12AmountFreeElopements(
            weddingAlbum12AmountFreeElopements + 1
          );
        }

        setWeddingAlbum12ProfessionalDesignAmountElopements(
          weddingAlbum12ProfessionalDesignAmountElopements - 1
        );
      }
    }
  };

  // handle 'professional design' amount and price
  // deps on 'professional design' selected state, album amount
  useEffect(() => {
    // if (!uriChangeElopements) {
    if (weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );
      // setWeddingAlbum10ProfessionalDesignAmountElopements(
      //   weddingAlbum10AmountElopements
      // );
    }
    if (!weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10AmountElopements
      );
      setWeddingAlbum10AmountFreeElopements(0);
    }

    if (weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12AmountElopements
      );
      // setWeddingAlbum12ProfessionalDesignAmountElopements(
      //   weddingAlbum12AmountElopements
      // );
    }
    if (!weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12AmountElopements
      );
      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12AmountElopements
      );
      setWeddingAlbum12AmountFreeElopements(0);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
  ]);

  // handle professional design amount and free DIY amount
  useEffect(() => {
    // if (!uriChangeElopements) {
    if (
      weddingAlbum10ProfessionalDesignAmountElopements >
      weddingAlbum10AmountElopements
    ) {
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10AmountElopements
      );
    }
    if (
      weddingAlbum12ProfessionalDesignAmountElopements >
      weddingAlbum12AmountElopements
    ) {
      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12AmountElopements
      );
    }
    if (weddingAlbum10ProfessionalDesignAmountElopements > 0) {
      setWeddingAlbum10AmountFreeElopements(
        weddingAlbum10AmountElopements -
          weddingAlbum10ProfessionalDesignAmountElopements
      );
    }
    if (weddingAlbum12ProfessionalDesignAmountElopements > 0) {
      setWeddingAlbum12AmountFreeElopements(
        weddingAlbum12AmountElopements -
          weddingAlbum12ProfessionalDesignAmountElopements
      );
    }
    if (weddingAlbum10ProfessionalDesignAmountElopements === 0) {
      setWeddingAlbum10ProfessionalDesignSelectedElopements(false);
      setWeddingAlbum10AmountFreeElopements(0);
    }
    if (weddingAlbum12ProfessionalDesignAmountElopements === 0) {
      setWeddingAlbum12ProfessionalDesignSelectedElopements(false);
      setWeddingAlbum12AmountFreeElopements(0);
    }
    // }
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum12AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // handle wedding album price total and
  // 'professional design' amount
  // deps wedding album amount
  useEffect(() => {
    if (!uriChangeElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );

      if (weddingAlbum10ProfessionalDesignAmountElopements === 0) {
        setWeddingAlbum10ProfessionalDesignSelectedElopements(false);
        setWeddingAlbum10AmountFreeElopements(0);
      }

      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12ProfessionalDesignAmountElopements
      );

      if (weddingAlbum12ProfessionalDesignAmountElopements === 0) {
        setWeddingAlbum12ProfessionalDesignSelectedElopements(false);
        setWeddingAlbum12AmountFreeElopements(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // handle local storage for 10x10 wedding album
  useEffect(() => {
    setLocalStorageValue(
      'weddingAlbum10SelectedElopements',
      weddingAlbum10SelectedElopements
    );
    setLocalStorageValue(
      'weddingAlbum10ProfessionalDesignSelectedElopements',
      weddingAlbum10ProfessionalDesignSelectedElopements
    );
    setLocalStorageValue(
      'weddingAlbum10AmountElopements',
      weddingAlbum10AmountElopements
    );
    setLocalStorageValue(
      'weddingAlbum10AmountFreeElopements',
      weddingAlbum10AmountFreeElopements
    );
    setLocalStorageValue(
      'weddingAlbum10ProfessionalDesignAmountElopements',
      weddingAlbum10ProfessionalDesignAmountElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10SelectedElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum10AmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
  ]);

  // handle local storage for 12x12 wedding album
  useEffect(() => {
    setLocalStorageValue(
      'weddingAlbum12SelectedElopements',
      weddingAlbum12SelectedElopements
    );
    setLocalStorageValue(
      'weddingAlbum12ProfessionalDesignSelectedElopements',
      weddingAlbum12ProfessionalDesignSelectedElopements
    );
    setLocalStorageValue(
      'weddingAlbum12AmountElopements',
      weddingAlbum12AmountElopements
    );
    setLocalStorageValue(
      'weddingAlbum12AmountFreeElopements',
      weddingAlbum12AmountFreeElopements
    );
    setLocalStorageValue(
      'weddingAlbum12ProfessionalDesignAmountElopements',
      weddingAlbum12ProfessionalDesignAmountElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum12SelectedElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12AmountFreeElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);
  //#endregion

  //#region Premium package Elopements
  // photo
  const [
    premiumPhotoPackageSelectedElopements,
    setPremiumPhotoPackageSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'premiumPhotoPackageSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [
    premiumPhotoPackagePriceElopements,
    setPremiumPhotoPackagePriceElopements,
  ] = useState(Number(premiumPhotoPackageElopements.price));
  const [premiumPhotoPackagePriceDefaultElopements] = useState(
    Number(premiumPhotoPackageElopements.price)
  );
  // TODO: if/when needed, combined with premiumPhotoPackagePriceDiscountElopements
  // const [premiumPhotoPackagePriceDiscountElopements] = useState(
  //   Number(premiumPhotoPackageElopements.discount)
  // );
  const [premiumPhotoPackageDescriptionElopements] = useState(
    premiumPhotoPackageElopements.description
  );
  const [
    premiumPhotoPackagePopupTitleElopements,
    setPremiumPhotoPackagePopupTitleElopements,
  ] = useState(premiumPhotoPackageElopements.popupTitle);
  // TODO: if/when needed
  // const [premiumPhotoPackagePopupTitleDefaultElopements] = useState(
  //   premiumPhotoPackageElopements.popupTitle
  // );
  // const [premiumPhotoPackagePopupTitleDiscountElopements] = useState(
  //   premiumPhotoPackageElopements.popupTitleDiscount
  // );
  const [premiumPhotoPackagePopupBodyElopements] = useState(
    premiumPhotoPackageElopements.popupBody
  );

  const handlePremiumPhotoPackageSelectedElopements = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setPremiumPhotoPackageSelectedElopements(
        !premiumPhotoPackageSelectedElopements
      );
      handleKeyPressed(ev.keyCode);
    }
  };

  // video
  const [
    premiumVideoPackageSelectedElopements,
    setPremiumVideoPackageSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'premiumVideoPackageSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });

  const [
    premiumVideoPackagePriceElopements,
    setPremiumVideoPackagePriceElopements,
  ] = useState(Number(premiumVideoPackageElopements.price));
  const [premiumVideoPackagePriceDefaultElopements] = useState(
    Number(premiumVideoPackageElopements.price)
  );
  const [premiumVideoPackagePriceDiscountElopements] = useState(
    Number(premiumVideoPackageElopements.discount)
  );
  const [premiumVideoPackageDescriptionElopements] = useState(
    premiumVideoPackageElopements.description
  );
  // const [premiumVideoPackageDescriptionDefaultElopements] = useState(
  //   premiumVideoPackageElopements.description
  // );
  // in elopmenets there is no extended trailer
  // so this is not needed
  // const [
  //   premiumVideoPackageDescriptionElopements,
  //   setPremiumVideoPackageDescriptionElopements,
  // ] = useState('');
  // const [premiumVideoPackageDescriptionDefaultElopements] = useState(
  //   premiumVideoPackageElopements.description
  // );

  const [
    premiumVideoPackagePopupTitleElopements,
    setPremiumVideoPackagePopupTitleElopements,
  ] = useState(premiumVideoPackageElopements.popupTitle);
  const [premiumVideoPackagePopupTitleDefaultElopements] = useState(
    premiumVideoPackageElopements.popupTitle
  );
  const [premiumVideoPackagePopupTitleDiscountElopements] = useState(
    premiumVideoPackageElopements.popupTitleDiscount
  );
  const [premiumVideoPackagePopupBodyElopements] = useState(
    premiumVideoPackageElopements.popupBody
  );

  const handlePremiumVideoPackageSelectedElopements = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setPremiumVideoPackageSelectedElopements(
        !premiumVideoPackageSelectedElopements
      );
      handleKeyPressed(ev.keyCode);
    }
  };
  //#endregion

  // Videography state
  //#region One videographer elopements
  const [
    oneVideographerSelectedElopements,
    setOneVideographerSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'oneVideographerSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });
  const [
    oneVideographerControlElopements,
    setOneVideographerControlElopements,
  ] = useState('');
  const [oneVideographerPriceDefaultElopements] = useState(
    Number(oneVideographerElopements.priceFor1)
  );
  const [oneVideographerPriceElopements, setOneVideographerPriceElopements] =
    useState(Number(oneVideographerElopements.priceFor1));
  const [oneVideographerHourElopements, setOneVideographerHourElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('oneVideographerHourElopements');
      return storedValue !== null ? storedValue : 1;
    });
  const [oneVideographerPricePerHourElopements] = useState(
    Number(oneVideographerElopements.pricePerHour)
  );

  // handle selected state
  // TODO: accessiblity state
  const handleOneVideographerSelectedElopements = (ev) => {
    clearStoragePackageStateElopements();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOneVideographerSelectedElopements(!oneVideographerSelectedElopements);
    }
    handleKeyPressed(ev.keyCode);
  };

  // handle hours amount
  const handleOneVideographerDataElopements = (control, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (oneVideographerSelectedElopements === false) {
        setOneVideographerSelectedElopements(true);
      }

      if (control === 'decrease') {
        setOneVideographerControlElopements('decrease');
        if (oneVideographerHourElopements > 1) {
          setOneVideographerHourElopements(oneVideographerHourElopements - 1);
        }
      }
      if (control === 'increase') {
        setOneVideographerControlElopements('increase');
        if (oneVideographerHourElopements < 4) {
          setOneVideographerHourElopements(oneVideographerHourElopements + 1);
        }
      }
    }
    handleKeyPressed(ev.keyCode);
  };

  // handle price based on hours
  const handleOneVideographerPriceElopements = () => {
    if (oneVideographerHourElopements > 1) {
      let correctedOneVideographerPrice = oneVideographerPriceDefaultElopements;

      for (let i = 1; i < oneVideographerHourElopements; i++) {
        correctedOneVideographerPrice += oneVideographerPricePerHourElopements;
      }

      setOneVideographerPriceElopements(correctedOneVideographerPrice);
    }
  };

  useEffect(() => {
    setOneVideographerHourElopements(oneVideographerHourElopements);

    if (oneVideographerControlElopements === 'decrease') {
      setOneVideographerPriceElopements(
        oneVideographerPriceElopements - oneVideographerPricePerHourElopements
      );
    }

    if (oneVideographerControlElopements === 'increase') {
      setOneVideographerPriceElopements(
        oneVideographerPriceElopements + oneVideographerPricePerHourElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerHourElopements, oneVideographerControlElopements]);

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'oneVideographerSelectedElopements',
      oneVideographerSelectedElopements
    );
    setLocalStorageValue(
      'oneVideographerHourElopements',
      oneVideographerHourElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerSelectedElopements, oneVideographerHourElopements]);
  //#endregion

  //#region Officiant state elopements
  const [officiantSelectedElopements, setOfficiantSelectedElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('officiantSelectedElopements');
      return storedValue !== null ? storedValue : false;
    });
  const [officiantPriceElopements, setOfficiantPriceElopements] = useState(
    Number(officiantElopements.price)
  );

  // handle selected state
  // TODO: accessiblity state
  const handleOfficiantSelectedElopements = (ev) => {
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOfficiantSelectedElopements(!officiantSelectedElopements);
    }
    handleKeyPressed(ev.keyCode);
  };

  // handle officiant pricing
  const handleOfficiantPriceElopements = () => {
    if (officiantSelectedElopements) {
      setOfficiantPriceElopements(Number(officiantElopements.price));
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'officiantSelectedElopements',
      officiantSelectedElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officiantSelectedElopements]);
  //#endregion

  //#region Premium Packages Elopements
  // Setting title in PackageModal
  const [isPremiumPackageAddedElopements, setIsPremiumPackageAddedElopements] =
    useState(false);

  // set title to either title or discountTitle
  // and adjust pricing of video
  useEffect(() => {
    if (!premiumPhotoPackageSelectedElopements) {
      setIsPremiumPackageAddedElopements(false);
      setPremiumVideoPackagePopupTitleElopements(
        premiumVideoPackagePopupTitleDefaultElopements
      );
      setPremiumVideoPackagePriceElopements(
        premiumVideoPackagePriceDefaultElopements
      );
    }
    if (premiumPhotoPackageSelectedElopements) {
      setIsPremiumPackageAddedElopements(true);
      setPremiumVideoPackagePopupTitleElopements(
        premiumVideoPackagePopupTitleDiscountElopements
      );
      setPremiumVideoPackagePriceElopements(
        premiumVideoPackagePriceDiscountElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPhotoPackageSelectedElopements]);

  // handle local storage for premium packages elopements
  useEffect(() => {
    setLocalStorageValue(
      'premiumPhotoPackageSelectedElopements',
      premiumPhotoPackageSelectedElopements
    );
    setLocalStorageValue(
      'premiumVideoPackageSelectedElopements',
      premiumVideoPackageSelectedElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    premiumPhotoPackageSelectedElopements,
    premiumVideoPackageSelectedElopements,
  ]);
  //#endregion

  //#region Storage elopements
  // handle storage package
  const [storageElopementsInfo] = useState(storageElopements.info);
  const [storageElopementsPackagePrice, setStorageElopementsPackagePrice] =
    useState(0);

  // photo storage package
  const [storageElopementsPhotoPackageInfo] = useState(
    storageElopements.photo.info
  );
  const [storageElopementsPhotoPackageOption1PopupText] = useState(
    storageElopements.photo.options.option1Popuptext
  );
  const [storageElopementsPhotoPackageOption1SidebarText] = useState(
    storageElopements.photo.options.option1Sidebartext
  );
  const [storageElopementsPhotoPackageOption1Price] = useState(
    storageElopements.photo.options.option1Price
  );
  const [storageElopementsPhotoPackageOption2PopupText] = useState(
    storageElopements.photo.options.option2Popuptext
  );
  const [storageElopementsPhotoPackageOption2SidebarText] = useState(
    storageElopements.photo.options.option2Sidebartext
  );
  const [storageElopementsPhotoPackageOption2Price] = useState(
    storageElopements.photo.options.option2Price
  );
  const [storageElopementsPhotoPackageOption3PopupText] = useState(
    storageElopements.photo.options.option3Popuptext
  );
  const [storageElopementsPhotoPackageOption3SidebarText] = useState(
    storageElopements.photo.options.option3Sidebartext
  );
  const [storageElopementsPhotoPackageOption3Price] = useState(
    storageElopements.photo.options.option3Price
  );

  // video storage package
  const [storageElopementsVideoPackageInfo] = useState(
    storageElopements.video.info
  );
  const [storageElopementsVideoPackageOption1PopupText] = useState(
    storageElopements.video.options.option1PopupText
  );
  const [storageElopementsVideoPackageOption1SidebarText] = useState(
    storageElopements.video.options.option1SidebarText
  );
  const [storageElopementsVideoPackageOption1Price] = useState(
    storageElopements.video.options.option1Price
  );
  const [storageElopementsVideoPackageOption2PopupText] = useState(
    storageElopements.video.options.option2PopupText
  );
  const [storageElopementsVideoPackageOption2SidebarText] = useState(
    storageElopements.video.options.option2SidebarText
  );
  const [storageElopementsVideoPackageOption2Price] = useState(
    storageElopements.video.options.option2Price
  );
  const [storageElopementsVideoPackageOption3PopupText] = useState(
    storageElopements.video.options.option3PopupText
  );
  const [storageElopementsVideoPackageOption3SidebarText] = useState(
    storageElopements.video.options.option3SidebarText
  );
  const [storageElopementsVideoPackageOption3Price] = useState(
    storageElopements.video.options.option3Price
  );

  // photo and video storage package
  const [storageElopementsPhotoAndVideoPackageInfo] = useState(
    storageElopements.photoVideo.info
  );
  const [storageElopementsPhotoAndVideoPackageOption1PopupText] = useState(
    storageElopements.photoVideo.options.option1PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption1SidebarText] = useState(
    storageElopements.photoVideo.options.option1SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption1Price] = useState(
    storageElopements.photoVideo.options.option1Price
  );
  const [storageElopementsPhotoAndVideoPackageOption2PopupText] = useState(
    storageElopements.photoVideo.options.option2PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption2SidebarText] = useState(
    storageElopements.photoVideo.options.option2SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption2Price] = useState(
    storageElopements.photoVideo.options.option2Price
  );
  const [storageElopementsPhotoAndVideoPackageOption3PopupText] = useState(
    storageElopements.photoVideo.options.option3PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption3SidebarText] = useState(
    storageElopements.photoVideo.options.option3SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption3Price] = useState(
    storageElopements.photoVideo.options.option3Price
  );

  // handle storage state
  const [
    storagePackageSelectedElopements,
    setStoragePackageSelectedElopements,
  ] = useState(() => {
    const storedValue = getLocalStorageValue(
      'storagePackageSelectedElopements'
    );
    return storedValue !== null ? storedValue : false;
  });

  // TODO: accessibility state
  const handleStoragePackageSelectedElopements = () => {
    setStoragePackageSelectedElopements(!storagePackageSelectedElopements);
  };

  // clear storage package state
  useEffect(() => {
    if (storagePackageSelectedElopements === false) {
      clearStoragePackageStateElopements();
    }
  }, [
    storagePackageSelectedElopements,
    onePhotographerSelectedElopements,
    oneVideographerSelectedElopements,
  ]);

  // clear storage package state on package change
  // examp. if storage is selected with one photographer
  // then when one videographer is added clear storage package state
  const clearStoragePackageStateElopements = () => {
    setCheckedStorageValueElopements(null);
    setCheckedStorageValueModalElopements(null);
    setToggleCheckoutBtnInfo(false);
    setStoragePackageSelectedElopements(false);
    setStorageElopementsPackagePrice(0);
  };

  const [storagePackageTypeElopements, setStoragePackageTypeElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('storagePackageTypeElopements');
      return storedValue !== null ? storedValue : '';
    });

  const handleStoragePackageElopements = (type) => {
    setStoragePackageTypeElopements(type);
  };
  //#endregion

  //#region Storage in package modal
  // state is separate for weddings and elopements, handleStoragePackage()
  const [
    checkedStorageValueModalElopements,
    setCheckedStorageValueModalElopements,
  ] = useState(null);
  const [checkedStorageValueElopements, setCheckedStorageValueElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('checkedStorageValueElopements');
      return storedValue !== null ? storedValue : null;
    });

  // handle storage package inputs
  const handleStorageElopementsPackageChange = (price, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (checkedStorageValueElopements === ev.target.value) {
        setCheckedStorageValueElopements(null);
        setCheckedStorageValueModalElopements(null);
        setToggleCheckoutBtnInfo(false);
        setStoragePackageSelectedElopements(false);
        setStorageElopementsPackagePrice(0);
      } else {
        setCheckedStorageValueElopements(ev.target.value);
        setCheckedStorageValueModalElopements(ev.target.value);
        setToggleCheckoutBtnInfo(true);
        setStoragePackageSelectedElopements(true);
        setStorageElopementsPackagePrice(Number(price));
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // sync inputs in modal and in sidebar with state
  useEffect(() => {
    if (checkedStorageValueModalElopements !== null) {
      setCheckedStorageValueElopements(checkedStorageValueModalElopements);
    }
  }, [
    checkedStorageValueModalElopements,
    checkedStorageValueElopements,
    storagePackageTypeElopements,
  ]);

  const handleStoragePriceElopements = () => {
    if (storagePackageSelectedElopements) {
      if (storagePackageTypeElopements === 'photo') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption3Price
          );
        }
      }
      if (storagePackageTypeElopements === 'video') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption3Price
          );
        }
      }
      if (storagePackageTypeElopements === 'photoVideo') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption3Price
          );
        }
      }
    }
  };

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'storagePackageSelectedElopements',
      storagePackageSelectedElopements
    );
    setLocalStorageValue(
      'storagePackageTypeElopements',
      storagePackageTypeElopements
    );
    setLocalStorageValue(
      'checkedStorageValueElopements',
      checkedStorageValueElopements
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storagePackageSelectedElopements,
    storagePackageTypeElopements,
    checkedStorageValueElopements,
  ]);
  //#endregion

  //#region Storage packages
  const [photoDownloadLinkSelected, setPhotoDownloadLinkSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('photoDownloadLinkSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [photoDownloadLinkPrice] = useState(
    Number(configuratorStorage.photoDownloadLinkPrice)
  );
  const [videoDownloadLinkSelected, setVideoDownloadLinkSelected] = useState(
    () => {
      const storedValue = getLocalStorageValue('videoDownloadLinkSelected');
      return storedValue !== null ? storedValue : false;
    }
  );
  const [videoDownloadLinkPrice] = useState(
    Number(configuratorStorage.videoDownloadLinkPrice)
  );
  const [photoVideoDownloadLinkSelected, setPhotoVideoDownloadLinkSelected] =
    useState(() => {
      const storedValue = getLocalStorageValue(
        'photoVideoDownloadLinkSelected'
      );
      return storedValue !== null ? storedValue : false;
    });
  const [photoVideoDownloadLinkPrice] = useState(
    Number(configuratorStorage.photoVideoDownloadLinkPrice)
  );

  const [packagesStorageSelected, setPackagesStorageSelected] = useState(false);
  const [packagesStoragePrice, setPackagesStoragePrice] = useState(0);

  const handlePackagesStorage = (option, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (option === 1) {
        setPhotoDownloadLinkSelected(!photoDownloadLinkSelected);
        setVideoDownloadLinkSelected(false);
        setPhotoVideoDownloadLinkSelected(false);
        setPackagesStoragePrice(photoDownloadLinkPrice);
        setGrandTotalPriceStorage(photoDownloadLinkPrice);
      }
      if (option === 2) {
        setPhotoDownloadLinkSelected(false);
        setVideoDownloadLinkSelected(!videoDownloadLinkSelected);
        setPhotoVideoDownloadLinkSelected(false);
        setPackagesStoragePrice(videoDownloadLinkPrice);
        setGrandTotalPriceStorage(videoDownloadLinkPrice);
      }
      if (option === 3) {
        setPhotoDownloadLinkSelected(false);
        setVideoDownloadLinkSelected(false);
        setPhotoVideoDownloadLinkSelected(!photoVideoDownloadLinkSelected);
        setPackagesStoragePrice(photoVideoDownloadLinkPrice);
        setGrandTotalPriceStorage(photoVideoDownloadLinkPrice);
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // TODO: accessibility state
  const handlePackagesStorageSelected = () => {
    setPackagesStorageSelected(!packagesStorageSelected);
    setPhotoDownloadLinkSelected(false);
    setVideoDownloadLinkSelected(false);
    setPhotoVideoDownloadLinkSelected(false);
  };

  const handlePackagesStoragePricing = () => {
    if (photoDownloadLinkSelected) {
      setPackagesStoragePrice(photoDownloadLinkPrice);
      setGrandTotalPriceStorage(photoDownloadLinkPrice);
    }
    if (videoDownloadLinkSelected) {
      setPackagesStoragePrice(videoDownloadLinkPrice);
      setGrandTotalPriceStorage(videoDownloadLinkPrice);
    }
    if (photoVideoDownloadLinkSelected) {
      setPackagesStoragePrice(photoVideoDownloadLinkPrice);
      setGrandTotalPriceStorage(photoVideoDownloadLinkPrice);
    }
  };

  useEffect(() => {
    if (
      photoDownloadLinkSelected ||
      videoDownloadLinkSelected ||
      photoVideoDownloadLinkSelected
    ) {
      setPackagesStorageSelected(true);
      handlePackagesStoragePricing();
    } else {
      setPackagesStorageSelected(false);
      setPackagesStoragePrice(0);
      setGrandTotalPriceStorage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoDownloadLinkSelected,
    videoDownloadLinkSelected,
    photoVideoDownloadLinkSelected,
  ]);

  // handle local storage
  useEffect(() => {
    setLocalStorageValue(
      'photoDownloadLinkSelected',
      photoDownloadLinkSelected
    );
    setLocalStorageValue(
      'videoDownloadLinkSelected',
      videoDownloadLinkSelected
    );
    setLocalStorageValue(
      'photoVideoDownloadLinkSelected',
      photoVideoDownloadLinkSelected
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoDownloadLinkSelected,
    videoDownloadLinkSelected,
    photoVideoDownloadLinkSelected,
  ]);
  //#endregion

  // TODO: clean up, handle opened accordion state Elopements
  // const [isPhotographySelectedElopements, setIsPhotographySelectedElopements] =
  //   useState(false);
  // TODO: clean up
  // useEffect(() => {
  //   if (onePhotographerSelectedElopements) {
  //     // setIsPhotographySelectedElopements(true);
  //   }
  //   if (oneVideographerSelectedElopements) {
  //     // setIsVideographySelectedElopements(true);
  //   }
  //   if (officiantSelectedElopements) {
  //     // setIsOfficiantSelectedElopements(true);
  //   }
  // }, [
  //   onePhotographerSelectedElopements,
  //   oneVideographerSelectedElopements,
  //   officiantSelectedElopements,
  // ]);

  //#region Defaults for both, wedding and elopements
  // isMobile check
  const [isMobile, setIsMobile] = useState(false);
  // show package weddings
  const [showPackage, setShowPackage] = useState(false);
  // show package elopements
  const [showPackageElopements, setShowPackageElopements] = useState(false);
  // show package storage
  const [showPackageStorage, setShowPackageStorage] = useState(false);

  // TODO: accessibility state
  const togglePackage = () => {
    setShowPackage(!showPackage);
  };

  // TODO: accessibility state
  const togglePackageElopements = () => {
    setShowPackageElopements(!showPackageElopements);
  };

  // TODO: accessibility state
  const togglePackageStorage = () => {
    setShowPackageStorage(!showPackageStorage);
  };

  // add overflow:hidden to body on modal open
  // to remove scroll on background
  useEffect(() => {
    if (showPackage || showPackageElopements) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style = '';
    }
  }, [showPackage, showPackageElopements]);

  // add/remove isMobile css class
  useEffect(() => {
    const bodyEl = document.body;

    const resizeBodyObserver = new ResizeObserver(() => {
      if (bodyEl.getBoundingClientRect().width < 1400) {
        // if (bodyEl.getBoundingClientRect().width < 1280) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    resizeBodyObserver.observe(bodyEl);

    return () => {
      resizeBodyObserver.unobserve(bodyEl);
    };
  }, [isMobile]);

  // hide mobile sidebar on resize
  useEffect(() => {
    if (!isMobile) {
      setShowPackage(false);
      setShowPackageElopements(false);
    }
  }, [isMobile]);
  //#endregion

  //#region Calculate grand total elopements
  const calculateGrandTotalElopements = () => {
    // gatsby build ssr error fix
    if (typeof document !== 'undefined') {
      // console.log('CALCULATING GRAND TOTAL ELOPEMENTS');
      let totalPriceElopements;

      const packagePriceElementsElopements = document.querySelectorAll(
        '.item-price-elopements'
      );

      const packagePriceMappedElopements = Array.from(
        packagePriceElementsElopements
      ).map((el) => {
        return Number(el.textContent);
      });

      // add all prices
      totalPriceElopements = packagePriceMappedElopements.reduce(
        (a, b) => a + b,
        0
      );
      // console.log('TOTAL PRICE ELOPEMENTS', totalPriceElopements);
      setGrandTotalPriceElopements(totalPriceElopements);
    }
  };

  // grand total price elopements
  useEffect(() => {
    calculateGrandTotalElopements();
  }, [
    // elopements
    onePhotographerSelectedElopements,
    onePhotographerPriceElopements,
    onePhotographerHourElopements,
    weddingAlbum10SelectedElopements,
    weddingAlbum10PriceTotalElopements,
    weddingAlbum12SelectedElopements,
    weddingAlbum12PriceTotalElopements,
    oneVideographerSelectedElopements,
    oneVideographerPriceElopements,
    officiantSelectedElopements,
    officiantPriceElopements,
    // storage
    storagePackageSelectedElopements,
    storageElopementsPackagePrice,
    // premium
    premiumPhotoPackageSelectedElopements,
    premiumVideoPackageSelectedElopements,
  ]);
  //#endregion

  // calculate deposit weddings
  const [depositPrice, setDepositPrice] = useState(0);

  // package price
  const [packagePrice, setPackagePrice] = useState(0);
  const [packagePriceElopements, setPackagePriceElopements] = useState(0);

  // tax
  const [taxRate] = useState(0.08875);
  const [tax, setTax] = useState(0);
  const [taxElopements, setTaxElopements] = useState(0);

  useEffect(() => {
    setDepositPrice(Number((packagePrice + tax) * 0.2).toFixed(2));
    // setDepositPrice(Number(grandTotalPrice * 0.2).toFixed(2));
  }, [grandTotalPrice, packagePrice, tax]);

  // calculate deposit elopements
  const [depositPriceElopements, setDepositPriceElopements] = useState(0);

  useEffect(() => {
    setDepositPriceElopements(
      Number(grandTotalPriceElopements * 0.2).toFixed(2)
    );
  }, [grandTotalPriceElopements]);

  //#region Calculate grand total packages storage
  const calculateGrandTotalStorage = () => {
    if (grandTotalPriceStorage === 0) {
      setShowPackageStorage(false);
    }
    // let totalPriceStorage;

    // const packagePriceElementsStorage = document.querySelectorAll(
    //   '.item-price-storage-selected'
    // );

    // const packagePriceMappedStorage = Array.from(
    //   packagePriceElementsStorage
    // ).map((el) => {
    //   return Number(el.textContent);
    // });

    // // add all prices
    // totalPriceStorage = packagePriceMappedStorage.reduce((a, b) => a + b, 0);
    // setGrandTotalPriceStorage(totalPriceStorage);
  };

  // grand total price packages storage
  useEffect(() => {
    calculateGrandTotalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoDownloadLinkSelected,
    photoDownloadLinkPrice,
    videoDownloadLinkSelected,
    videoDownloadLinkPrice,
    photoVideoDownloadLinkSelected,
    photoVideoDownloadLinkPrice,
    packagesStoragePrice,
  ]);
  //#endregion

  // hide sidebar when grand total is 0
  // redirect to package configurator from checkout page
  useEffect(() => {
    if (grandTotalPrice === 0) {
      setShowPackage(false);
    }

    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (grandTotalPrice === 0 && document.body.className === 'checkout') {
      const currentPage = document.body.className;

      if (
        packageConfiguratorWeddings &&
        !packageConfiguratorElopements &&
        currentPage !== 'wedding-packages' &&
        onePhotographerSelected === false &&
        engagementOneHourSelected === false &&
        engagementTwoHoursSelected === false &&
        oneVideographerSelected === false &&
        photoBoothBasicSelected === false &&
        photoBoothAllInclusiveSelected === false
      ) {
        navigate('/wedding-packages/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPrice]);

  // hide sidebar when grand total is 0
  // redirect to package configurator elopements from checkout page
  useEffect(() => {
    if (grandTotalPriceElopements === 0) {
      setShowPackageElopements(false);
    }

    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (
      grandTotalPriceElopements === 0 &&
      document.body.className === 'checkout'
    ) {
      const currentPage = document.body.className;

      if (
        !packageConfiguratorWeddings &&
        packageConfiguratorElopements &&
        currentPage !== 'elopement' &&
        onePhotographerSelectedElopements === false &&
        oneVideographerSelectedElopements === false &&
        officiantSelectedElopements === false
      ) {
        navigate('/wedding/elopement/');
      }
    } else {
      calculateGrandTotalElopements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPriceElopements]);

  // redirect to package configurator storage from checkout page
  useEffect(() => {
    if (grandTotalPriceStorage === 0) {
      setShowPackageStorage(false);
    }
    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (
      grandTotalPriceStorage === 0 &&
      document.body.className === 'checkout'
    ) {
      const currentPage = document.body.className;

      if (
        !packageConfiguratorWeddings &&
        !packageConfiguratorElopements &&
        packageConfiguratorStorage &&
        currentPage !== 'storage-packages' &&
        photoDownloadLinkSelected === false &&
        videoDownloadLinkSelected === false &&
        photoVideoDownloadLinkSelected === false
      ) {
        navigate('/storage-packages/');
      }
    }
    // else {
    // calculateGrandTotalStorage();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPriceStorage]);

  //#region Handle discount
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (
      onePhotographerSelected &&
      (photoBoothBasicSelected || photoBoothAllInclusiveSelected)
    ) {
      setDiscountApplied(true);
      setDiscountAmount(() => {
        // hadrcode it for now, beacuse its the only discount
        // maybe add it as an option in wordpress
        return '100';
      });
    } else {
      setDiscountApplied(false);
      setDiscountAmount(0);
    }
  }, [
    onePhotographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);

  // discount value
  const [discountValue, setDiscountValue] = useState(0);

  useEffect(() => {
    if (discountAmount) {
      setDiscountValue(-discountAmount);
    } else {
      setDiscountValue(0);
    }
  }, [discountAmount, grandTotalPrice]);
  //#endregion

  //#region Tax
  const [weddingAlbum10Tax, setWeddingAlbum10Tax] = useState(0);
  const [weddingAlbum12Tax, setWeddingAlbum12Tax] = useState(0);
  const [photoBoothBasicTax, setPhotoBoothBasicTax] = useState(0);
  const [photoBoothAllInclusiveTax, setPhotoBoothAllInclusiveTax] = useState(0);

  // calculate tax for package options that have tax
  useEffect(() => {
    // console.log('photoBoothPackagePrice', photoBoothPackagePrice);
    if (weddingAlbum10Selected) {
      setWeddingAlbum10Tax(Number(calculateWeddingAlbum10Tax().toFixed(2)));
    }
    if (!weddingAlbum10Selected) {
      setWeddingAlbum10Tax(0);
    }

    if (weddingAlbum12Selected) {
      setWeddingAlbum12Tax(Number(calculateWeddingAlbum12Tax().toFixed(2)));
    }
    if (!weddingAlbum12Selected) {
      setWeddingAlbum12Tax(0);
    }

    if (photoBoothBasicSelected) {
      setPhotoBoothBasicTax(Number(calculatePhotoBoothBasicTax().toFixed(2)));
    }
    if (!photoBoothBasicSelected) {
      setPhotoBoothBasicTax(0);
    }

    if (photoBoothAllInclusiveSelected) {
      setPhotoBoothAllInclusiveTax(
        Number(calculatePhotoBoothAllInclusiveTax().toFixed(2))
      );
    }
    if (!photoBoothAllInclusiveSelected) {
      setPhotoBoothAllInclusiveTax(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    weddingAlbum10PriceTotal,
    weddingAlbum12PriceTotal,
    photoBoothPackagePrice,
    grandTotalPrice,
  ]);

  // set cumulative tax
  useEffect(() => {
    if (
      weddingAlbum10Selected ||
      weddingAlbum12Selected ||
      photoBoothBasicSelected ||
      photoBoothAllInclusiveSelected
    ) {
      setTax(
        Number(
          (
            weddingAlbum10Tax +
            weddingAlbum12Tax +
            photoBoothBasicTax +
            photoBoothAllInclusiveTax
          ).toFixed(2)
        )
      );
    } else {
      setTax(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    weddingAlbum10Tax,
    weddingAlbum12Tax,
    photoBoothBasicTax,
    photoBoothAllInclusiveTax,
  ]);

  const calculateWeddingAlbum10Tax = () => {
    return Number((weddingAlbum10Price * taxRate).toFixed(2));
  };

  const calculateWeddingAlbum12Tax = () => {
    return Number((weddingAlbum12Price * taxRate).toFixed(2));
  };

  const calculatePhotoBoothBasicTax = () => {
    return Number((photoBoothBasicPackagePrice * taxRate).toFixed(2));
  };

  const calculatePhotoBoothAllInclusiveTax = () => {
    return Number((photoBoothAllInclusivePrice * taxRate).toFixed(2));
  };

  // calculate tax
  // calculateTaxFee() {
  //   // 8.875% WAlbums i Photo Booth
  //   this.taxFee = 0;
  //   if (this.weddingAlbum10Selected && !this.weddingAlbum12Selected) {
  //     this.taxFee = Number(this.calculateWeddingAlbum10Tax()).toFixed(2);
  //   }
  //   if (this.weddingAlbum12Selected && !this.weddingAlbum10Selected) {
  //     this.taxFee = Number(this.calculateWeddingAlbum12Tax()).toFixed(2);
  //   }
  //   if (this.weddingAlbum10Selected && this.weddingAlbum12Selected) {
  //     this.taxFee = (
  //       Number(this.calculateWeddingAlbum10Tax()) +
  //       Number(this.calculateWeddingAlbum12Tax())
  //     ).toFixed(2);

  //     if (this.photoBoothBasicPackageSelected) {
  //       this.taxFee = (
  //         Number(this.calculatePhotoBoothBasicTax()) + Number(this.taxFee)
  //       ).toFixed(2);
  //     }
  //     if (this.photoBoothAllInclusivePackageSelected) {
  //       this.taxFee = (
  //         Number(this.calculatePhotoBoothAllInclusiveTax()) +
  //         Number(this.taxFee)
  //       ).toFixed(2);
  //     }
  //   }

  //   //Photo Booth tax fee
  //   if (!this.weddingAlbum10Selected || !this.weddingAlbum12Selected) {
  //     let photoBoothTax = 0;
  //     if (this.photoBoothBasicPackageSelected) {
  //       photoBoothTax = Number(this.calculatePhotoBoothBasicTax());
  //       this.taxFee = photoBoothTax + Number(this.taxFee);
  //     }
  //     if (this.photoBoothAllInclusivePackageSelected) {
  //       photoBoothTax = Number(this.calculatePhotoBoothAllInclusiveTax());
  //       this.taxFee = photoBoothTax + Number(this.taxFee);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   // setTax(0);
  //   if (weddingAlbum10Selected) {
  //     setTax(Number(tax + weddingAlbum10Tax));
  //   }
  //   if (weddingAlbum12Selected) {
  //     setTax(Number(tax + weddingAlbum12Tax));
  //   }
  //   if (photoBoothBasicSelected) {
  //     // if (tax === 0) {
  //     setTax(Number(photoBoothBasicTax));
  //     // }
  //     // else {
  //     //   setTax(0);
  //     // }
  //   }
  //   if (!photoBoothBasicSelected) {
  //     setTax(0);
  //     setPhotoBoothBasicTax(0);
  //   }
  //   if (photoBoothAllInclusiveSelected) {
  //     setTax(Number(tax + photoBoothAllInclusiveTax));
  //   }

  //   console.log('TAx', tax, photoBoothBasicTax);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   weddingAlbum10Selected,
  //   weddingAlbum12Selected,
  //   photoBoothBasicSelected,
  //   photoBoothAllInclusiveSelected,
  //   weddingAlbum10Tax,
  //   weddingAlbum12Tax,
  //   photoBoothBasicTax,
  //   photoBoothAllInclusiveTax,
  //   // grandTotalPrice,
  // ]);
  //#endregion

  //#region TODO: package price

  useEffect(() => {
    console.log(
      'PACKAGE PRICE',
      packagePrice,
      discountAmount,
      weddingAlbum10Tax,
      weddingAlbum12Tax,
      photoBoothBasicTax,
      photoBoothAllInclusiveTax
    );
    const com =
      Number(grandTotalPrice) -
      //   TODO: recheck for other values
      //   Number(discountAmount) -
      Number(weddingAlbum10Tax) -
      Number(weddingAlbum12Tax) -
      Number(photoBoothBasicTax) -
      Number(photoBoothAllInclusiveTax) -
      Number(ccFee);
    if (
      discountApplied ||
      weddingAlbum10Selected ||
      weddingAlbum12Selected ||
      photoBoothBasicSelected ||
      photoBoothAllInclusiveSelected
    ) {
      console.log('111111111111111111111111');
      setPackagePrice(Number(com.toFixed(2)));
    } else {
      console.log('222222222222222222222222222');
      setPackagePrice(Number(packagePrice));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    packagePrice,
    discountApplied,
    discountAmount,
    weddingAlbum10Selected,
    weddingAlbum10Tax,
    weddingAlbum12Selected,
    weddingAlbum12Tax,
    photoBoothBasicSelected,
    photoBoothBasicTax,
    photoBoothAllInclusiveSelected,
    photoBoothAllInclusiveTax,
    grandTotalPrice,
  ]);
  //#endregion

  //#region TODO: fees
  const [ccRate] = useState(0.0357);
  const [ccFee, setFee] = useState(0);
  const [ccFeeImmutable, setFeeImmutable] = useState(0);
  const [ccFeeElopements, setFeeElopements] = useState(0);
  const [ccFeeImmutableElopements, setFeeImmutableElopements] = useState(0);

  // payment method
  const [paymentMethod, setPaymentMethod] = useState();
  // useEffect(() => {
  //   console.log('PAYMENT METHODDDDDDDDDDDD', paymentMethod);
  //   // setPaymentMethod(paymentMethod);
  // }, []);
  // balance
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    console.log('--------->', discountApplied, packagePrice, grandTotalPrice);
    // if (discountApplied) {
    //   setFee(Number(packagePrice * ccRate).toFixed(2));
    // } else {
    //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    // }
    setFeeImmutable(Number((packagePrice + tax) * ccRate).toFixed(2));

    if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
      setFee(Number((packagePrice + tax) * ccRate).toFixed(2));
      //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    } else {
      setFee(0);
    }
    console.log('--------->', ccFee, paymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPrice, paymentMethod]);
  // }, [discountApplied, grandTotalPrice, packagePrice]);

  useEffect(() => {
    if (grandTotalPrice > 0) {
      const calculateBalance = grandTotalPrice - depositPrice;
      setBalance(Number(calculateBalance).toFixed(2));
    }
  }, [grandTotalPrice, depositPrice]);
  // }, [grandTotalPrice, depositPrice, balance]);
  //#endregion

  //#region Calculate grand total weddings
  const calculateGrandTotal = () => {
    // gatsbyjs ssr fix build error
    if (typeof document !== 'undefined') {
      let totalPrice;

      const packagePriceElements = document.querySelectorAll('.item-price');

      const packagePriceMapped = Array.from(packagePriceElements).map((el) => {
        return Number(el.textContent);
      });

      // add all prices
      totalPrice = packagePriceMapped.reduce((a, b) => a + b, 0);
      setPackagePrice(Number(totalPrice));
      // console.log('GRAND TOTAL PRICE', totalPrice, tax, ccFee);
      // TODO: .toFixed(2) is not working
      setGrandTotalPrice(Number(totalPrice) + Number(tax));
      // setGrandTotalPrice(totalPrice + Number(tax) + Number(ccFee));
      if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
        setGrandTotalPrice(
          (Number(totalPrice) + Number(tax) + Number(ccFee)).toFixed(2)
        );
      }
      // console.log('PAYMENT METHOD', paymentMethod);
    }
  };

  useEffect(() => {
    calculateGrandTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // weddings
    onePhotographerSelected,
    onePhotographerPrice,
    secondPhotographerSelected,
    secondPhotographerPrice,
    engagementPhotoShootSelected,
    engagementPhotoShootPrice,
    weddingAlbum10Selected,
    weddingAlbum10PriceTotal,
    weddingAlbum12Selected,
    weddingAlbum12PriceTotal,
    photoRetouchingSelected,
    photoRetouchingPackagePrice,
    expeditedPhotoProcessingSelected,
    expeditedPhotoProcessingPrice,
    oneVideographerSelected,
    oneVideographerPrice,
    cinematographySelected,
    cinematographyPrice,
    trailerOnlySelected,
    trailerOnlyPrice,
    extendedTrailerSelected,
    extendedTrailerPrice,
    traditionalSelected,
    traditionalPrice,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    photoBoothPackagePrice,
    // storage
    storagePackageSelected,
    storagePackagePrice,
    // premium
    premiumPhotoPackageSelected,
    premiumVideoPackageSelected,
    tax,
    ccFee,
    paymentMethod,
    // sneak peek
    socialMediaPackagePhotoSelected,
    socialMediaPackagePhotoPrice,
    socialMediaPackageVideoSelected,
    socialMediaPackageVideoPrice,
    socialMediaPackagePhotoAndVideoSelected,
    socialMediaPackagePhotoAndVideoPrice,
  ]);
  //#endregion

  //#region Show sidebars
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSidebarElopements, setShowSidebarElopements] = useState(false);

  const checkForPackagesSelected = () => {
    // only account for parent packages
    if (
      onePhotographerSelected ||
      engagementOneHourSelected ||
      engagementTwoHoursSelected ||
      oneVideographerSelected ||
      photoBoothBasicSelected ||
      photoBoothAllInclusiveSelected ||
      // added
      weddingAlbum10Selected ||
      weddingAlbum12Selected ||
      photoRetouching10Selected ||
      photoRetouching20Selected ||
      photoRetouching30Selected ||
      expeditedPhotoProcessingSelected ||
      // sneak peek
      socialMediaPackagePhotoSelected ||
      socialMediaPackageVideoSelected ||
      socialMediaPackagePhotoAndVideoSelected
    ) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  };

  const checkForPackagesSelectedElopements = () => {
    if (
      // only account for parent packages
      onePhotographerSelectedElopements ||
      oneVideographerSelectedElopements ||
      officiantSelectedElopements
    ) {
      setShowSidebarElopements(true);
    } else {
      setShowSidebarElopements(false);
    }
  };

  useEffect(() => {
    checkForPackagesSelected();
    checkForPackagesSelectedElopements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // weddings
    onePhotographerSelected,
    engagementOneHourSelected,
    engagementTwoHoursSelected,
    oneVideographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    // elopements
    onePhotographerSelectedElopements,
    oneVideographerSelectedElopements,
    officiantSelectedElopements,
    // added
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoRetouching10Selected,
    photoRetouching20Selected,
    photoRetouching30Selected,
    expeditedPhotoProcessingSelected,
    // sneak peek
    socialMediaPackagePhotoSelected,
    socialMediaPackageVideoSelected,
    socialMediaPackagePhotoAndVideoSelected,
  ]);
  //#endregion

  //#region On mount recalculates the price
  // of all the packages for weddings and elopements
  useState(() => {
    console.log('CONFIGURATOR CONTEXT ACTIVE');
    // TODO: recheck this
    // calculateWeddingAlbum10Tax();
    // weddings
    handleOnePhotographerPrice();
    handleSecondPhotographerPrice();
    handlePriceTotalWeddingAlbum10(weddingAlbum10Amount);
    handlePriceTotalWeddingAlbum12(weddingAlbum12Amount);
    handleEngagementsPricing();
    handlePhotoRetouchingPricing();
    handleOneVideographerPrice();
    handleSecondVideographerPrice();
    handleStoragePrice();
    // elopements
    handleOnePhotographerPriceElopements();
    handleOneVideographerPriceElopements();
    handleOfficiantPriceElopements();
    handlePriceTotalWeddingAlbum10Elopements(weddingAlbum10AmountElopements);
    handlePriceTotalWeddingAlbum12Elopements(weddingAlbum12AmountElopements);
    handleStoragePriceElopements();
    // packages storage
    handlePackagesStoragePricing();
    // calculate grand totals
    calculateGrandTotal();
    calculateGrandTotalElopements();
  }, []);
  //#endregion

  return (
    <ConfiguratorContext.Provider
      value={{
        isDev,
        handleKeyUp,
        packageConfiguratorWeddings,
        setPackageConfiguratorWeddings,
        packageConfiguratorElopements,
        setPackageConfiguratorElopements,
        packageConfiguratorStorage,
        setPackageConfiguratorStorage,
        isMobile,
        showPackage,
        showPackageElopements,
        showPackageStorage,
        discountApplied,
        discountAmount,
        setGrandTotalPrice,
        grandTotalPrice,
        calculateGrandTotal: () => calculateGrandTotal(),
        setGrandTotalPriceElopements,
        grandTotalPriceElopements,
        calculateGrandTotalElopements: () => calculateGrandTotalElopements(),
        showSidebarElopements,
        setGrandTotalPriceStorage,
        grandTotalPriceStorage,
        showSidebar,
        depositPrice,
        depositPriceElopements,
        discountValue,
        packagePrice,
        tax,
        ccFee,
        ccFeeImmutable,
        packagePriceElopements,
        taxElopements,
        ccFeeElopements,
        ccFeeImmutableElopements,
        balance,
        setPaymentMethod,
        setShowPackage,
        setShowPackageElopements,
        setShowPackageStorage,
        togglePackage: (ev) => togglePackage(ev),
        togglePackageElopements: (ev) => togglePackageElopements(ev),
        togglePackageStorage: (ev) => togglePackageStorage(ev),
        // weddings
        // onePhotographer
        onePhotographerPrice,
        onePhotographerSelected,
        setOnePhotographerSelected,
        onePhotographerHour,
        setOnePhotographerHour,
        onePhotographerCCImages,
        setOnePhotographerCCImages,
        onePhotographerPriceDefault,
        setOnePhotographerPrice,
        onePhotographerPriceForBelow8,
        onePhotographerPriceForAbove8,
        onePhotographerCCImages8,
        onePhotographerCCImagesAbove8,
        onePhotographerCCImagesBelow8,
        // secondPhotographer
        secondPhotographerPrice,
        secondPhotographerSelected,
        setSecondPhotographerSelected,
        secondPhotographerHour,
        setSecondPhotographerHour,
        secondPhotographerCCImages,
        setSecondPhotographerCCImages,
        secondPhotographerPriceDefault,
        setSecondPhotographerPrice,
        secondPhotographerPriceForBelow8,
        secondPhotographerPriceForAbove8,
        secondPhotographerCCImages8,
        secondPhotographerCCImagesAbove8,
        secondPhotographerCCImagesBelow8,
        // engagement
        engagementPhotoShootSelected,
        handleEngagementPhotoshootSelected,
        engagementPhotoShootPackageInfo,
        engagementPhotoShootPrice,
        engagementOneHourSelected,
        engagementOneHourPrice,
        setEngagementOneHourSelected,
        engagementTwoHoursSelected,
        engagementTwoHoursPrice,
        setEngagementTwoHoursSelected,
        setEngagementPhotoShootPrice,
        // wedding albums
        uriChange,
        setUriChange,
        weddingAlbum10Selected,
        weddingAlbum10PriceTotal,
        weddingAlbum10Price,
        setWeddingAlbum10Price,
        weddingAlbum10PriceDefault,
        weddingAlbum10Amount,
        weddingAlbum10Info,
        setWeddingAlbum10Selected,
        weddingAlbum10ProfessionalDesignSelected,
        setWeddingAlbum10ProfessionalDesignSelected,
        weddingAlbum10ProfessionalDesignPrice,
        setWeddingAlbum10ProfessionalDesignPriceTotal,
        weddingAlbum10ProfessionalDesignAmount,
        weddingAlbum10ProfessionalDesignInfo,
        weddingAlbum10ProfessionalDesignPriceTotal,
        weddingAlbum10AmountFree,
        weddingAlbum10AmountFreeInfo,
        weddingAlbum10ShippingFeeTotal,
        setWeddingAlbum10Amount,
        setWeddingAlbum10AmountFree,
        setWeddingAlbum10ProfessionalDesignAmount,
        weddingAlbum10ShippingFee,
        setWeddingAlbum10ShippingFeeTotal,
        weddingAlbum12Selected,
        setWeddingAlbum12Selected,
        weddingAlbum12PriceTotal,
        weddingAlbum12PriceDefault,
        setWeddingAlbum12Price,
        weddingAlbum12ShippingFee,
        setWeddingAlbum12ShippingFeeTotal,
        weddingAlbum12Amount,
        weddingAlbum12Info,
        weddingAlbum12ProfessionalDesignSelected,
        setWeddingAlbum12ProfessionalDesignSelected,
        weddingAlbum12ProfessionalDesignAmount,
        weddingAlbum12ProfessionalDesignInfo,
        weddingAlbum12ProfessionalDesignPriceTotal,
        setWeddingAlbum12ProfessionalDesignPriceTotal,
        weddingAlbum12AmountFree,
        weddingAlbum12AmountFreeInfo,
        weddingAlbum12ShippingFeeTotal,
        setWeddingAlbum12Amount,
        setWeddingAlbum12AmountFree,
        setWeddingAlbum12ProfessionalDesignAmount,
        // retouching
        photoRetouchingSelected,
        photoRetouchingPackageInfo,
        photoRetouchingPackagePrice,
        retouchedPhotos10Price,
        photoRetouching10Selected,
        setPhotoRetouching10Selected,
        retouchedPhotos20Price,
        photoRetouching20Selected,
        setPhotoRetouching20Selected,
        retouchedPhotos30Price,
        photoRetouching30Selected,
        setPhotoRetouching30Selected,
        setPhotoRetouchingPackageInfo,
        setPhotoRetouchingPackagePrice,
        // expedited photo processing
        expeditedPhotoProcessingSelected,
        expeditedPhotoProcessingPrice,
        setExpeditedPhotoProcessingSelected,
        // oneVideographer
        oneVideographerSelected,
        setOneVideographerSelected,
        oneVideographerHour,
        setOneVideographerHour,
        oneVideographerPriceDefault,
        oneVideographerPrice,
        oneVideographerPriceWithOnePhotographer,
        oneVideographerPriceForBelow8,
        oneVideographerPriceForAbove8,
        setOneVideographerPrice,
        // secondVideographer
        secondVideographerSelected,
        setSecondVideographerSelected,
        secondVideographerHour,
        setSecondVideographerHour,
        secondVideographerPriceDefault,
        secondVideographerPrice,
        secondVideographerPriceForBelow8,
        secondVideographerPriceForAbove8,
        setSecondVideographerPrice,
        // editing options
        cinematographySelected,
        setCinematographySelected,
        handleCinematographySelected: (ev) => handleCinematographySelected(ev),
        cinematographyPrice,
        setTrailerOnlySelected,
        trailerOnlySelected,
        handleTrailerOnlySelected: (ev) => handleTrailerOnlySelected(ev),
        trailerOnlyPrice,
        extendedTrailerSelected,
        setExtendedTrailerSelected,
        handleExtendedTrailerSelected,
        extendedTrailerPrice,
        traditionalSelected,
        setTraditionalSelected,
        handleTraditionalSelected,
        traditionalPrice,
        // photo booth
        photoBoothBasicPackagePriceDefault,
        photoBoothBasicPackagePriceWithOnePhotographer,
        photoBoothBasicPackagePrice,
        setPhotoBoothBasicPackagePrice,
        photoBoothBasicSelected,
        setPhotoBoothBasicSelected,
        photoBoothAllInclusivePriceDefault,
        photoBoothAllInclusivePriceWithOnePhotographer,
        photoBoothAllInclusivePrice,
        setPhotoBoothAllInclusivePrice,
        photoBoothAllInclusiveSelected,
        setPhotoBoothAllInclusiveSelected,
        photoBoothPackagePrice,
        setPhotoBoothPackagePrice,
        // methods weddings
        handleOnePhotographerSelected: (ev) =>
          handleOnePhotographerSelected(ev),
        handleOnePhotographerData: (control, ev) =>
          handleOnePhotographerData(control, ev),
        handleOnePhotographerPrice: () => handleOnePhotographerPrice(),
        handleSecondPhotographerSelected: (ev) =>
          handleSecondPhotographerSelected(ev),
        handleSecondPhotographerData: (control, ev) =>
          handleSecondPhotographerData(control, ev),
        handleSecondPhotographerPrice: () => handleSecondPhotographerPrice(),
        handleEngagementPhotoshoot: (option, ev) =>
          handleEngagementPhotoshoot(option, ev),
        handleOneVideographerSelected: (ev) =>
          handleOneVideographerSelected(ev),
        handleOneVideographerData: (control, ev) =>
          handleOneVideographerData(control, ev),
        handleOneVideographerPrice: () => handleOneVideographerPrice(),
        handleSecondVideographerSelected: (ev) =>
          handleSecondVideographerSelected(ev),
        handleSecondVideographerData: (control, ev) =>
          handleSecondVideographerData(control, ev),
        handleSecondVideographerPrice: () => handleSecondVideographerPrice(),
        // TODO: check if ev is needed for keypress
        handleWeddingAlbumSelected,
        handleWeddingAlbumAmount: (album, control) =>
          handleWeddingAlbumAmount(album, control),
        handleWeddingAlbumProfessionalDesignSelected: (album) =>
          handleWeddingAlbumProfessionalDesignSelected(album),
        handleWeddingAlbumProfessionalDesignAmount: (album, control) =>
          handleWeddingAlbumProfessionalDesignAmount(album, control),
        handlePriceTotalWeddingAlbum10: (w10a) =>
          handlePriceTotalWeddingAlbum10(w10a),
        handlePriceTotalWeddingAlbum12: (w12a) =>
          handlePriceTotalWeddingAlbum12(w12a),
        handlePhotoRetouchingSelected: (ev) =>
          handlePhotoRetouchingSelected(ev),
        handlePhotoRetouching: (option, ev) =>
          handlePhotoRetouching(option, ev),
        handleExpeditedPhotoProcessing: (option, ev) =>
          handleExpeditedPhotoProcessing(option, ev),
        handlePhotoBoothSelected: (option) => handlePhotoBoothSelected(option),
        // elopements
        // onePhotographerElopements
        onePhotographerSelectedElopements,
        setOnePhotographerSelectedElopements,
        onePhotographerHourElopements,
        setOnePhotographerHourElopements,
        onePhotographerPriceElopements,
        // TODO: proveri PriceDefault svuda da li koristis i dalje
        onePhotographerPriceDefaultElopements,
        setOnePhotographerPriceElopements,
        handleOnePhotographerPriceElopements: () =>
          handleOnePhotographerPriceElopements(),
        onePhotographerPricePerHourElopements,
        // isPhotographySelectedElopements,
        // oneVideographerElopements
        oneVideographerSelectedElopements,
        setOneVideographerSelectedElopements,
        oneVideographerPriceElopements,
        oneVideographerPriceDefaultElopements,
        oneVideographerPricePerHourElopements,
        setOneVideographerPriceElopements,
        oneVideographerHourElopements,
        setOneVideographerHourElopements,
        // weddingAlbumElopements
        uriChangeElopements,
        setUriChangeElopements,
        weddingAlbum10SelectedElopements,
        weddingAlbum10PriceTotalElopements,
        weddingAlbum10PriceElopements,
        setWeddingAlbum10PriceElopements,
        weddingAlbum10PriceDefaultElopements,
        weddingAlbum10AmountElopements,
        weddingAlbum10InfoElopements,
        setWeddingAlbum10SelectedElopements,
        weddingAlbum10ProfessionalDesignSelectedElopements,
        setWeddingAlbum10ProfessionalDesignSelectedElopements,
        weddingAlbum10ProfessionalDesignAmountElopements,
        weddingAlbum10ProfessionalDesignInfoElopements,
        weddingAlbum10ProfessionalDesignPriceTotalElopements,
        setWeddingAlbum10ProfessionalDesignPriceTotalElopements,
        weddingAlbum10AmountFreeElopements,
        weddingAlbum10AmountFreeInfoElopements,
        weddingAlbum10ShippingFeeTotalElopements,
        setWeddingAlbum10AmountElopements,
        setWeddingAlbum10AmountFreeElopements,
        setWeddingAlbum10ProfessionalDesignAmountElopements,
        weddingAlbum10ShippingFeeElopements,
        setWeddingAlbum10ShippingFeeTotalElopements,
        weddingAlbum12SelectedElopements,
        setWeddingAlbum12SelectedElopements,
        weddingAlbum12PriceTotalElopements,
        weddingAlbum12PriceDefaultElopements,
        setWeddingAlbum12PriceElopements,
        weddingAlbum12ShippingFeeElopements,
        setWeddingAlbum12ShippingFeeTotalElopements,
        weddingAlbum12AmountElopements,
        weddingAlbum12InfoElopements,
        weddingAlbum12ProfessionalDesignSelectedElopements,
        setWeddingAlbum12ProfessionalDesignSelectedElopements,
        weddingAlbum12ProfessionalDesignAmountElopements,
        weddingAlbum12ProfessionalDesignInfoElopements,
        weddingAlbum12ProfessionalDesignPriceElopements,
        weddingAlbum12ProfessionalDesignPriceTotalElopements,
        setWeddingAlbum12ProfessionalDesignPriceTotalElopements,
        weddingAlbum12AmountFreeElopements,
        weddingAlbum12AmountFreeInfoElopements,
        weddingAlbum12ShippingFeeTotalElopements,
        setWeddingAlbum12AmountElopements,
        setWeddingAlbum12AmountFreeElopements,
        setWeddingAlbum12ProfessionalDesignAmountElopements,
        // officiantElopements
        officiantSelectedElopements,
        officiantPriceElopements,
        setOfficiantSelectedElopements,
        // methods Elopements
        handleWeddingAlbumAmountElopements: (album, control) =>
          handleWeddingAlbumAmountElopements(album, control),
        handleOfficiantSelectedElopements: (ev) =>
          handleOfficiantSelectedElopements(ev),
        // TODO: recheck setIsPhotographySelectedElopements use?
        // setIsPhotographySelectedElopements: () =>
        //   setIsPhotographySelectedElopements(),
        handleOnePhotographerSelectedElopements: (ev) =>
          handleOnePhotographerSelectedElopements(ev),
        handleOnePhotographerDataElopements: (control, ev) =>
          handleOnePhotographerDataElopements(control, ev),
        handleOneVideographerSelectedElopements: (ev) =>
          handleOneVideographerSelectedElopements(ev),
        handleOneVideographerDataElopements: (control, ev) =>
          handleOneVideographerDataElopements(control, ev),
        handleOneVideographerPriceElopements: () =>
          handleOneVideographerPriceElopements(),
        handleWeddingAlbumSelectedElopements,
        handleWeddingAlbumProfessionalDesignSelectedElopements: (album) =>
          handleWeddingAlbumProfessionalDesignSelectedElopements(album),
        handleWeddingAlbumProfessionalDesignAmountElopements: (
          album,
          control
        ) =>
          handleWeddingAlbumProfessionalDesignAmountElopements(album, control),
        handlePriceTotalWeddingAlbum10Elopements: (w10a) =>
          handlePriceTotalWeddingAlbum10Elopements(w10a),
        handlePriceTotalWeddingAlbum12Elopements: (w12a) =>
          handlePriceTotalWeddingAlbum12Elopements(w12a),
        handleOfficiantPriceElopements: () => handleOfficiantPriceElopements(),
        // weddings, elopements modal data
        weddingAlbumInfoModal,
        professionalAlbumDesignInfoModal,
        weddingAlbumInfoModalElopements,
        professionalAlbumDesignInfoModalElopements,
        cinematographyInfo,
        trailerOnlyInfo,
        extendedTrailerInfo,
        traditionalInfo,
        photoBoothInfo,
        photoRetouchingInfo,
        expeditedPhotoProcessingInfo,
        // storage modal
        checkedStorageValueModal,
        checkedStorageValueModalElopements,
        // setCheckedStorageValueModal,
        toggleCheckoutBtnInfo,
        setToggleCheckoutBtnInfo,
        // storage
        checkedStorageValue,
        storagePackageSelected,
        storageInfo,
        storagePackageType,
        storagePackagePrice,
        storagePhotoPackageInfo,
        storagePhotoPackageOption1PopupText,
        storagePhotoPackageOption1SidebarText,
        storagePhotoPackageOption1Price,
        storagePhotoPackageOption2PopupText,
        storagePhotoPackageOption2SidebarText,
        storagePhotoPackageOption2Price,
        storagePhotoPackageOption3PopupText,
        storagePhotoPackageOption3SidebarText,
        storagePhotoPackageOption3Price,
        storageVideoPackageInfo,
        storageVideoPackageOption1PopupText,
        storageVideoPackageOption1SidebarText,
        storageVideoPackageOption1Price,
        storageVideoPackageOption2PopupText,
        storageVideoPackageOption2SidebarText,
        storageVideoPackageOption2Price,
        storageVideoPackageOption3PopupText,
        storageVideoPackageOption3SidebarText,
        storageVideoPackageOption3Price,
        storagePhotoAndVideoPackageInfo,
        storagePhotoAndVideoPackageOption1PopupText,
        storagePhotoAndVideoPackageOption1SidebarText,
        storagePhotoAndVideoPackageOption1Price,
        storagePhotoAndVideoPackageOption2PopupText,
        storagePhotoAndVideoPackageOption2SidebarText,
        storagePhotoAndVideoPackageOption2Price,
        storagePhotoAndVideoPackageOption3PopupText,
        storagePhotoAndVideoPackageOption3SidebarText,
        storagePhotoAndVideoPackageOption3Price,
        setStoragePackageType,
        setStoragePackageSelected,
        setStoragePackagePrice,
        handleStoragePackageSelected: () => handleStoragePackageSelected(),
        handleStoragePackage: (type) => handleStoragePackage(type),
        handleStoragePackageChange: (price, ev) =>
          handleStoragePackageChange(price, ev),
        // storage elopements
        storagePackageSelectedElopements,
        storageElopementsInfo,
        storagePackageTypeElopements,
        storageElopementsPackagePrice,
        storageElopementsPhotoPackageInfo,
        storageElopementsPhotoPackageOption1PopupText,
        storageElopementsPhotoPackageOption1SidebarText,
        storageElopementsPhotoPackageOption1Price,
        storageElopementsPhotoPackageOption2PopupText,
        storageElopementsPhotoPackageOption2SidebarText,
        storageElopementsPhotoPackageOption2Price,
        storageElopementsPhotoPackageOption3PopupText,
        storageElopementsPhotoPackageOption3SidebarText,
        storageElopementsPhotoPackageOption3Price,
        storageElopementsVideoPackageInfo,
        storageElopementsVideoPackageOption1PopupText,
        storageElopementsVideoPackageOption1SidebarText,
        storageElopementsVideoPackageOption1Price,
        storageElopementsVideoPackageOption2PopupText,
        storageElopementsVideoPackageOption2SidebarText,
        storageElopementsVideoPackageOption2Price,
        storageElopementsVideoPackageOption3PopupText,
        storageElopementsVideoPackageOption3SidebarText,
        storageElopementsVideoPackageOption3Price,
        storageElopementsPhotoAndVideoPackageInfo,
        storageElopementsPhotoAndVideoPackageOption1PopupText,
        storageElopementsPhotoAndVideoPackageOption1SidebarText,
        storageElopementsPhotoAndVideoPackageOption1Price,
        storageElopementsPhotoAndVideoPackageOption2PopupText,
        storageElopementsPhotoAndVideoPackageOption2SidebarText,
        storageElopementsPhotoAndVideoPackageOption2Price,
        storageElopementsPhotoAndVideoPackageOption3PopupText,
        storageElopementsPhotoAndVideoPackageOption3SidebarText,
        storageElopementsPhotoAndVideoPackageOption3Price,
        checkedStorageValueElopements,
        setStoragePackageTypeElopements,
        setStoragePackageSelectedElopements,
        setStorageElopementsPackagePrice,
        handleStoragePackageSelectedElopements: () =>
          handleStoragePackageSelectedElopements(),
        handleStoragePackageElopements: (type) =>
          handleStoragePackageElopements(type),
        handleStorageElopementsPackageChange: (e, price) =>
          handleStorageElopementsPackageChange(e, price),
        // local storage
        // packageConfiguratorWeddings,
        // packageConfiguratorElopements,
        // setPackageConfiguratorWeddings,
        // setPackageConfiguratorElopements,
        // packages storage
        photoDownloadLinkSelected,
        videoDownloadLinkSelected,
        photoVideoDownloadLinkSelected,
        setPhotoDownloadLinkSelected,
        setVideoDownloadLinkSelected,
        setPhotoVideoDownloadLinkSelected,
        photoDownloadLinkPrice,
        videoDownloadLinkPrice,
        photoVideoDownloadLinkPrice,
        packagesStorageSelected,
        packagesStoragePrice,
        setPackagesStoragePrice,
        handlePackagesStorageSelected: (ev) =>
          handlePackagesStorageSelected(ev),
        handlePackagesStorage: (option, ev) =>
          handlePackagesStorage(option, ev),
        // premium
        premiumPhotoPackagePrice,
        setPremiumPhotoPackageSelected,
        // premiumPhotoPackagePriceDefault,
        // premiumPhotoPackagePriceDiscount,
        setPremiumPhotoPackagePrice,
        premiumPhotoPackageDescription,
        premiumPhotoPackagePopupTitle,
        // premiumPhotoPackagePopupTitleDefault,
        // premiumPhotoPackagePopupTitleDiscount,
        setPremiumPhotoPackagePopupTitle,
        premiumPhotoPackagePopupBody,
        premiumPhotoPackageSelected,
        handlePremiumPhotoPackageSelected: (ev) =>
          handlePremiumPhotoPackageSelected(ev),
        premiumVideoPackagePrice,
        setPremiumVideoPackageSelected,
        premiumVideoPackagePriceDefault,
        // premiumVideoPackagePriceDiscount,
        setPremiumVideoPackagePrice,
        premiumVideoPackageDescription,
        premiumVideoPackagePopupTitle,
        // premiumVideoPackagePopupTitleDiscount,
        setPremiumVideoPackagePopupTitle,
        premiumVideoPackagePopupBody,
        premiumVideoPackageSelected,
        handlePremiumVideoPackageSelected: (ev) =>
          handlePremiumVideoPackageSelected(ev),
        isPremiumPackageAdded,
        // social media package (sneak peek)
        socialMediaPackagePhotoSelected,
        socialMediaPackagePhotoPrice,
        socialMediaPackagePhotoReels,
        socialMediaPackageVideoSelected,
        socialMediaPackageVideoPrice,
        socialMediaPackageVideoReels,
        socialMediaPackagePhotoAndVideoSelected,
        socialMediaPackagePhotoAndVideoPrice,
        socialMediaPackagePhotoAndVideoReels,
        setSocialMediaPackagePhotoSelected,
        setSocialMediaPackageVideoSelected,
        setSocialMediaPackagePhotoAndVideoSelected,
        handleSocialMediaPackagePhotoSelected: (ev) =>
          handleSocialMediaPackagePhotoSelected(ev),
        handleSocialMediaPackageVideoSelected: (ev) =>
          handleSocialMediaPackageVideoSelected(ev),
        handleSocialMediaPackagePhotoAndVideoSelected: (ev) =>
          handleSocialMediaPackagePhotoAndVideoSelected(ev),
        // premium elopements
        premiumPhotoPackagePriceElopements,
        setPremiumPhotoPackageSelectedElopements,
        // premiumPhotoPackagePriceDefaultElopements,
        // premiumPhotoPackagePriceDiscountElopements,
        setPremiumPhotoPackagePriceElopements,
        premiumPhotoPackageDescriptionElopements,
        premiumPhotoPackagePopupTitleElopements,
        // premiumPhotoPackagePopupTitleDefaultElopements,
        // premiumPhotoPackagePopupTitleDiscountElopements,
        setPremiumPhotoPackagePopupTitleElopements,
        premiumPhotoPackagePopupBodyElopements,
        premiumPhotoPackageSelectedElopements,
        handlePremiumPhotoPackageSelectedElopements: (ev) =>
          handlePremiumPhotoPackageSelectedElopements(ev),
        premiumVideoPackagePriceElopements,
        setPremiumVideoPackageSelectedElopements,
        premiumVideoPackagePriceDefaultElopements,
        // premiumVideoPackagePriceDiscountElopements,
        setPremiumVideoPackagePriceElopements,
        premiumVideoPackageDescriptionElopements,
        premiumVideoPackagePopupTitleElopements,
        // premiumVideoPackagePopupTitleDiscountElopements,
        setPremiumVideoPackagePopupTitleElopements,
        premiumVideoPackagePopupBodyElopements,
        premiumVideoPackageSelectedElopements,
        handlePremiumVideoPackageSelectedElopements: (ev) =>
          handlePremiumVideoPackageSelectedElopements(ev),
        isPremiumPackageAddedElopements,
      }}
    >
      {children}
      {/* {props.children} */}
    </ConfiguratorContext.Provider>
  );
};

// export { ConfiguratorProvider };
